import React from 'react';
import PropTypes from 'prop-types';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalHeader from '../common/Modal/ModalHeader';
import FORM from '../constants/form.json';
import NewsBtnGroup from '../common/Buttons/NewsBtnGroup';
import getUrl from '../common/Rest/rest.utils';
import REST from '../common/Rest';
import makeRestCall from '../common/Rest/makeRestCall';

/**
 * Overview component
 * @returns {JSX} component
 * @constructor
 */
class NewsModal extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  /**
   * handleSubmitForm
   * @return {undefined}
   */
  async handleSubmitForm() {
    const { formValues, onClose } = this.props;

    if (formValues && formValues.avoidNews) {
      const url = getUrl(REST.urls.benutzerAvoidNews);
      await makeRestCall('POST', url);
    }

    onClose();
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      onClose,
      newsMsg,
      handleSubmit,
      countZurueckgewiesenAusgang,
      countUebertragungFehlerAusgang,
    } = this.props;

    return (
      <>
        <form onSubmit={handleSubmit(this.handleSubmitForm)}>
          <ModalHeader
            onClose={onClose}
            key="modal-header"
          >
            Aktuelle Information
          </ModalHeader>
          <div
            key="modal-body"
            className="manz-modal-body"
          >
            <div className="manz-modal-help-body manz-modal-news-body">
              {!!countZurueckgewiesenAusgang && (
                <>
                  <h5 className="bold">Zurückgewiesen</h5>
                  <div>
                    {`Sie haben Schriftsätze mit dem Status "Zurückgewiesen". Das bedeutet, dass bei der Übermittlung und neuerlichen elektronischen Prüfung des Schriftsatzes Fehler gefunden wurden.
            Öffnen Sie den Schriftsatz und gehen Sie auf „Protokoll & Drucken“. Im Protokoll können Sie nachlesen welche Fehler aufgetreten sind.
            Verbessern Sie diese – wählen Sie danach „Prüfen“ und übermitteln Sie den Schriftsatz erneut mit „Senden“.`}
                  </div>
                  <br />
                  <h6>
                    {'Beispiel: Grundbuchantrag mit Tagebuchzahl führt zu "Zurückgewiesen"'}
                  </h6>
                  <div className="newsModal-beispiel">
                    {'Fehler 1143 - Zu der angegebenen Aktenzahl BG 10 1920/2000 ist kein Antrag im Status Verbesserung'}
                  </div>
                  <div>
                    {'In diesem Fall haben Sie eine falsche Aktenzahl, oder Sie müssen den Rechtspfleger bei Gericht anrufen und ersuchen den Grundbuchsantrag auf "Verbesserung zu setzen".'}
                  </div>
                  <br />
                  <br />
                </>
              )}

              {!!countUebertragungFehlerAusgang && (
                <>
                  <h5 className="bold">Erneut senden (Übertragungsfehler)</h5>

                  <div>
                    <span>
                      Bei der Übermittlung sind Verbindungsprobleme aufgetreten. Kontrollieren Sie Ihre Internetverbindung und
                    </span>
                    <span className="font-weight-bold">
                      {' versenden Sie den Schriftsatz mit "Ausgang - Senden" erneut, '}
                    </span>
                    <span>
                      oder verwenden Sie
                    </span>
                    <span className="font-weight-bold">
                      {' “Erneut senden”.'}
                    </span>
                  </div>

                  <div className="font-weight-bold">
                    Erstellen oder kopieren Sie den Schriftsatz nicht (neu) - dies kann zu Doppelübertragungen führen!
                  </div>

                  <div>
                    Der Schriftsatz könnte bereits erfolgreich eingebracht worden sein, aber der Status konnte von der webERV Software nicht erfolgreich abgerufen werden.
                  </div>

                  <br />
                  <br />
                </>
              )}

              {!!newsMsg && (
                <>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: newsMsg }} />
                </>
              )}
            </div>

            <NewsBtnGroup />
          </div>
        </form>
      </>
    );
  }
}

NewsModal.defaultProps = {
  onClose: null,
  newsMsg: null,
  formValues: null,
  countZurueckgewiesenAusgang: 0,
  countUebertragungFehlerAusgang: 0,
};

NewsModal.propTypes = {
  onClose: PropTypes.func,
  newsMsg: PropTypes.string,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  countZurueckgewiesenAusgang: PropTypes.number,
  countUebertragungFehlerAusgang: PropTypes.number,
};

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {object} redux form
 */
export function mapStateToProps(state) {
  const form = {};
  const formName = FORM.NEWS_FORM;

  return {
    initialValues: {
      ...form,
    },
    formValues: getFormValues(formName)(state),
  };
}

let form = reduxForm({
  form: FORM.NEWS_FORM,
  enableReinitialize: true,
})(NewsModal);

form = connect(mapStateToProps)(form);

export default withRouter(form);
