import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from '../ReactSelectWrapper';
import { SELECT_ALL_OPTION } from '../ReactSelectWrapper/ReactSelect.constants';
import { getSelectedOption } from '../ReactSelectWrapper/ReactSelect.utils';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-oPadding.svg';
import { arrayHasItem, emptyArray, onUseEnterAsTab } from '../../utils/general.utils';

/**
 * form select component
 */
class Select extends React.Component {
  /**
   * constructor
   * init stuff and things
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    const { input, options } = props;

    this.state = {
      selectedOption: getSelectedOption(input && input.value, options || []),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  /**
   * handle default selection here
   * if a default value should be selected without affecting the values of the form
   * use the "defaultSelection"
   * @returns {undefined}
   */
  componentDidMount() {
    const { defaultSelection, options, onGetSelectItem } = this.props;
    if (defaultSelection) {
      const selectedOption = getSelectedOption(defaultSelection, options);
      this.setState({ selectedOption });
      if (onGetSelectItem) {
        onGetSelectItem(getSelectedOption(defaultSelection, options));
      }
    }
  }

  /**
   * component did update
   * @param {Object} prevProps prevProps
   * @returns {undefined}
   */
  componentDidUpdate(prevProps) {
    const {
      input,
      options,
      initDropdown,
      onGetSelectItem,
    } = this.props;
    const { selectedOption } = this.state;

    if (initDropdown !== prevProps?.initDropdown || input?.value !== prevProps?.input?.value || options !== prevProps?.options) {
      this._updateValueOfSingleSelect(input, selectedOption, options);
      this._updateValueOfMultiSelect(input);
    }

    if (onGetSelectItem) {
      onGetSelectItem(selectedOption);
    }
  }

  /**
   * updated value if it is not a multi select
   * @param {Object} input input
   * @param {Object} selectedOption selectedOption
   * @param {Array} options options
   * @return {undefined}
   * @private
   */
  _updateValueOfSingleSelect(input, selectedOption, options) {
    if (!Array.isArray(input.value)) {
      let sOption = selectedOption;

      const val = input?.value?.value ? input.value.value : input.value;
      if (val) {
        sOption = getSelectedOption(val, options);
      }

      if (sOption) {
        this.setState(() => ({
          selectedOption: sOption,
        }));
      }
    }
  }

  /**
   * update value if it is multi select
   * @param {Object} input input
   * @return {undefined}
   * @private
   */
  _updateValueOfMultiSelect(input) {
    if (arrayHasItem(input.value)) {
      this.setState({ selectedOption: input.value });
    } else if (emptyArray(input.value)) {
      this.setState({ selectedOption: [] });
    }
  }

  /**
   * custom on change event to return the value of an select option value label pair
   * @param {Object} selected react select selectedOption
   * @returns {undefined}
   */
  handleChange(selected) {
    const { selectedOption } = this.state;

    if (selectedOption !== selected) {
      const {
        input,
        onGetSelectItem,
        onInterceptChange,
        onInterceptChangeBySelectOptions,
      } = this.props;
      let onChangeParam = null;
      if (selected && selected.value !== SELECT_ALL_OPTION) {
        onChangeParam = selected.value;
      }

      if (Array.isArray(selected)) {
        onChangeParam = selected;
      }

      this.setState(() => ({
        selectedOption: selected,
      }));

      input.onChange(onChangeParam);

      if (onInterceptChange) {
        onInterceptChange(onChangeParam);
      }

      if (onGetSelectItem) {
        onGetSelectItem(selected);
      }

      if (onInterceptChangeBySelectOptions) {
        onInterceptChangeBySelectOptions(selected);
      }
    }
  }

  /**
   * handleKeyDown
   * @param {Object} event event
   * @return {undefined}
   */
  handleKeyDown(event) {
    const { onKeyDown, isUseEnterAsTab } = this.props;

    if (onKeyDown) {
      onKeyDown(event);
    }

    if (isUseEnterAsTab) {
      onUseEnterAsTab(event);
    }
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      id,
      label,
      tooltip,
      isMulti,
      options,
      disabled,
      ariaLabel,
      className,
      isRequired,
      placeholder,
      isSearchable,
      onInputChange,
      withDeprecated,
      noOptionsMessage,
      meta: {
        error,
        invalid,
        submitFailed,
      },
    } = this.props;
    const { selectedOption } = this.state;

    const classNames = className ? className.split(' ') : [];
    if (error && submitFailed) {
      classNames.push('form-validation-error');
    }

    const updatedOptions = arrayHasItem(options) ? (withDeprecated ? options : options.filter((v) => !v.deprecated).map((v) => v)) : [];

    if (arrayHasItem(updatedOptions)) {
      for (let i = 0; i < updatedOptions.length; i += 1) {
        const optionsInGroup = updatedOptions[i].options;
        if (arrayHasItem(optionsInGroup)) {
          for (let j = 0; j < optionsInGroup.length; j += 1) {
            if (optionsInGroup[j].deprecated) {
              optionsInGroup.splice(j, 1);
            }
          }
        }
      }
    }

    return (
      <div className={classNames.join(' ')} title={tooltip}>
        <ReactSelect
          inputId={id}
          title={tooltip}
          isMulti={isMulti}
          isDisabled={disabled}
          value={selectedOption}
          placeholder={placeholder}
          options={updatedOptions}
          isSearchable={isSearchable}
          ariaLabel={ariaLabel || label}
          classNamePrefix="react-select"
          onChange={this.handleChange}
          onInputChange={onInputChange}
          onKeyDown={this.handleKeyDown}
          noOptionsMessage={noOptionsMessage}
          className={isRequired ? 'isRequiredWrapper' : ''}
        />
        {
          invalid && submitFailed && error && (
            <span className="error">
              <InfoSVG title="" />
              {' '}
              {error}
            </span>
          )
        }
      </div>
    );
  }
}

Select.defaultProps = {
  id: null,
  options: [],
  label: null,
  meta: null,
  input: null,
  tooltip: null,
  isMulti: false,
  placeholder: '',
  disabled: false,
  ariaLabel: null,
  className: null,
  isRequired: false,
  onKeyDown: null,
  isSearchable: true,
  initDropdown: false,
  onInputChange: null,
  defaultSelection: null,
  withDeprecated: false,
  onGetSelectItem: null,
  isUseEnterAsTab: false,
  noOptionsMessage: null,
  onInterceptChange: null,
  onInterceptChangeBySelectOptions: null,
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  isMulti: PropTypes.bool,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onKeyDown: PropTypes.func,
  isSearchable: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  initDropdown: PropTypes.bool,
  withDeprecated: PropTypes.bool,
  onInputChange: PropTypes.func,
  onGetSelectItem: PropTypes.func,
  isUseEnterAsTab: PropTypes.bool,
  defaultSelection: PropTypes.string,
  onInterceptChange: PropTypes.func,
  noOptionsMessage: PropTypes.string,
  onInterceptChangeBySelectOptions: PropTypes.func,
};

export default Select;
