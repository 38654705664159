import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NavigationGroup from './NavigationGroup';
import NavigationGroupStatusCounter from './NavigationGroupStatusCounter';
import { storeFirstBreadcrumb } from '../../../common/Reducers/breadcrumbs.reducer';
import PATH from '../../../constants/path';
import DashboardIcon from '../../../common/Icons/DashboardIcon';
import DokEditIcon from '../../../common/Icons/DokEditIcon';
import SearchErgebnisIcon from '../../../common/Icons/SearchErgebnisIcon';
import NeueMitteilungIcon from '../../../common/Icons/NeueMitteilungIcon';
import InformationIcon from '../../../common/Icons/InformationIcon';
import PollingEventEmitter from '../../../common/Events/PollingEventEmitter';
import {
  EVENT_COUNT_GS_AUSGANG_UG_EINGANG,
  EVENT_RELOAD_AUSGANG_EINGANG_LIST,
  EVENT_RELOAD_AUSGANG_LIST,
  EVENT_RELOAD_EINGANG_LIST,
} from '../../../constants/js/events.constants';
import getUrl from '../../../common/Rest/rest.utils';
import REST from '../../../common/Rest';
import SCHRIFTSATZ_STATUS from '../../../constants/schriftsatz.status.json';
import makeRestCall from '../../../common/Rest/makeRestCall';
import { TIMEOUT_TYPE } from '../../../common/Rest/fetchWithTimeout';
import Polling from '../../../common/Polling/Polling';
import { checkOpenAPISwitch, getPollingTimeCountAusgangEingang } from '../../../constants/js/application.config';
import ErledigungenStatus from '../../../common/ErledigungenStatus/ErledigungenStatus';
import { OK } from '../../../common/Rest/http.status.codes';
import {
  clearAllTableExtraSearchParam,
  clearAllTableLocationSearch,
} from '../../../common/Reducers/simpleTable.reducer';
import { clearExekutionsantragBasisdaten } from '../../../common/Reducers/exekutionsantrag.reducer';
import VerlinkungIcon from '../../../common/Icons/VerlinkungIcon';

/**
 * navigation component
 * @param {Object} props props
 * @returns {JSX} component jsx
 */
class Navigation extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {
      countAusgang: 0,
      countEingang: 0,
      requestError: true,
      countZurueckgewiesenAusgang: 0,
      countUebertragungFehlerAusgang: 0,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCountSchriftverkehrStatus = this.handleCountSchriftverkehrStatus.bind(this);
    this.handleClickErledigungenStatusButton = this.handleClickErledigungenStatusButton.bind(this);
  }


  /**
   * componentDidMount
   * @return {undefined}
   */
  async componentDidMount() {
    PollingEventEmitter.on(EVENT_COUNT_GS_AUSGANG_UG_EINGANG, async () => this.handleCountSchriftverkehrStatus());
  }

  /**
   * componentDidUpdate
   * @param {Object} prevProps prevProps
   * @param {Object} prevState prevState
   * @return {Promise<void>} undefined
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.countAusgang !== this.state.countAusgang) {
      this.setState({}); // eslint-disable-line
    }
  }

  /**
   * componentWillUnmount
   * @return {undefined}
   */
  async componentWillUnmount() {
    PollingEventEmitter.off(EVENT_COUNT_GS_AUSGANG_UG_EINGANG, async () => this.handleCountSchriftverkehrStatus());
  }

  /**
   * count rest call schriftverkehr status for gesendete Schriftsaetze and ungelesene Erledigungen
   * @return {undefined}
   */
  async handleCountSchriftverkehrStatus() {
    const { appAuthorized, auth } = this.props;
    const appUserObj = (auth && auth.appUserObj);

    if (appAuthorized && appUserObj) {
      const url = getUrl(REST.urls.countSchriftverkehr);
      const statusResult = await makeRestCall('GET', url, null, null, true, null, TIMEOUT_TYPE.LOGIN);

      if (statusResult.status === OK && statusResult.body) {
        const status = statusResult.body;

        const erledigungenStatus = {
          lastCheck: status.lastCheck,
          countRVEinbringer: status.countRVEinbringer,
        };
        const countEingang = status.countSchriftverkehr && status.countSchriftverkehr[SCHRIFTSATZ_STATUS.NEU.value];
        const countAusgang = status.countSchriftverkehr && status.countSchriftverkehr[SCHRIFTSATZ_STATUS.GESENDET.value];
        const countZurueckgewiesenAusgang = status.countSchriftverkehr && status.countSchriftverkehr[SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value];
        const countUebertragungFehlerAusgang = status.countSchriftverkehr && status.countSchriftverkehr[SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value];
        const requestError = statusResult && statusResult.error && statusResult.status;

        this.setState({
          countEingang,
          countAusgang,
          countZurueckgewiesenAusgang,
          countUebertragungFehlerAusgang,
          requestError,
          erledigungenStatus,
        });
      }
    }
  }

  /**
   * handle click a link
   * @param {String} pathName pathName
   * @return {undefined}
   */
  handleClick(pathName) {
    const { actions, onClick } = this.props;

    actions.storeFirstBreadcrumb(pathName);
    actions.clearAllTableLocationSearch();
    actions.clearAllTableExtraSearchParam();
    actions.clearExekutionsantragBasisdaten();

    if (pathName === PATH.ROOT.value) {
      PollingEventEmitter.emit(EVENT_RELOAD_AUSGANG_EINGANG_LIST);
      this.handleCountSchriftverkehrStatus();
    } else if (pathName === PATH.AUSGANG.value) {
      PollingEventEmitter.emit(EVENT_RELOAD_AUSGANG_LIST);
      this.handleCountSchriftverkehrStatus();
    } else if (pathName === PATH.EINGANG.value) {
      PollingEventEmitter.emit(EVENT_RELOAD_EINGANG_LIST);
      this.handleCountSchriftverkehrStatus();
    }
    if (onClick) {
      onClick();
    }
  }

  /**
   * handleClickErledigungenStatusButton
   * @return {undefined}
   */
  handleClickErledigungenStatusButton() {
    const { history } = this.props;
    const pathname = history.location.pathname;

    if (pathname === PATH.DASHBOARD.value) {
      PollingEventEmitter.emit(EVENT_RELOAD_AUSGANG_EINGANG_LIST);
    } else if (pathname === PATH.EINGANG.value) {
      PollingEventEmitter.emit(EVENT_RELOAD_EINGANG_LIST);
    }

    this.handleCountSchriftverkehrStatus();
  }

  /**
   * render
   * @returns {JSX} component JSX
   */
  render() {
    const {
      isAdmin,
      isBurgerMenu,
      canKontaktImport,
      canOpenAPISpezifikation,
    } = this.props;
    const {
      countAusgang,
      countEingang,
      requestError,
      countZurueckgewiesenAusgang,
      countUebertragungFehlerAusgang,
      erledigungenStatus,
    } = this.state;

    const startseiteItems = [{
      label: 'Dashboard',
      link: PATH.DASHBOARD.value,
      click: () => this.handleClick(PATH.ROOT.value),
    }];

    const neuErfassenItems = [{
      label: 'Kontakte',
      link: PATH.KONTAKT.value,
      click: () => this.handleClick(PATH.KONTAKT.value),
    }, {
      label: 'Schriftsätze',
      link: `${PATH.SCHRIFTSATZ.value}${PATH.NEW.value}`,
      click: () => this.handleClick(`${PATH.SCHRIFTSATZ.value}${PATH.NEW.value}`),
    }];

    const searchItems = [{
      label: 'Kontakte',
      link: `${PATH.KONTAKT.value}${PATH.SUCHE.value}`,
      click: () => this.handleClick(`${PATH.KONTAKT.value}${PATH.SUCHE.value}`),
    }, {
      label: 'Schriftsätze',
      link: `${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}`,
      click: () => this.handleClick(`${PATH.SCHRIFTSATZ.value}${PATH.SUCHE.value}`),
    }];

    const sendItems = [{
      label: 'Ausgang',
      link: PATH.AUSGANG.value,
      click: () => this.handleClick(PATH.AUSGANG.value),
      tooltip: 'Schriftsätze werden gesendet - diese gelten erst dann als erfolgreich übermittelt, wenn sie den Status "Eingebracht" aufweisen!',
    }, {
      label: 'Eingang',
      link: PATH.EINGANG.value,
      click: () => this.handleClick(PATH.EINGANG.value),
      tooltip: 'Anzahl der ungelesenen Eingänge',
    },
    ];

    /*
    let helpItems = [{
      label: 'HilfeSupport & Support',
      link: 'https://hilfe.manz.at/weberv/hilfe_neueSW',
      external: true,
    },
    ];
     */

    let helpItems = [{
      label: PATH.HILFE_SUPPORT.label,
      link: PATH.HILFE_SUPPORT.value,
      click: () => this.handleClick(PATH.HILFE_SUPPORT.value),
    },
    ];

    if (isAdmin) {
      helpItems = helpItems.concat({
        label: PATH.KONFIGURATION.label,
        link: PATH.KONFIGURATION.value,
        click: () => this.handleClick(PATH.KONFIGURATION.value),
      });
    }

    if (canKontaktImport) {
      helpItems = helpItems.concat({
        label: PATH.DATEN_IMPORT.label,
        link: PATH.DATEN_IMPORT.value,
        click: () => this.handleClick(PATH.DATEN_IMPORT.value),
      });
    }

    const verlinkungItems = [{
      label: 'eAkteneinsicht',
      link: 'https://infodienste.manz.at/',
      external: true,
    }, {
      label: 'webERV Service',
      tooltip: 'Übermittlungsstelle',
      link: 'https://erv1.manz.at',
      external: true,
    },
    ];

    if (checkOpenAPISwitch() && canOpenAPISpezifikation) {
      helpItems = helpItems.concat({
        label: PATH.API.label,
        link: PATH.API.value,
        click: () => this.handleClick(PATH.API.value),
      });
    }

    return (
      <>
        <div className="navbar">
          <NavigationGroup
            key="nav-group-dash"
            isDashboard
            header="Dashboard"
            items={startseiteItems}
            isBurgerMenu={isBurgerMenu}
            iconComponent={(
              <DashboardIcon key="dashboard-icon" className="dashboard-icon" />
            )}
          />
          <NavigationGroup
            key="nav-group-new"
            header="Neu erfassen"
            items={neuErfassenItems}
            isBurgerMenu={isBurgerMenu}
            iconComponent={(
              <DokEditIcon key="docedit-icon" />
            )}
          />
          <NavigationGroup
            key="nav-group-search"
            items={searchItems}
            header="Suchen & Bearbeiten"
            isBurgerMenu={isBurgerMenu}
            iconComponent={(
              <SearchErgebnisIcon key="search-icon" />
            )}
          />
          <NavigationGroupStatusCounter
            key="nav-group-status"
            items={sendItems}
            header="Senden & Empfangen"
            isBurgerMenu={isBurgerMenu}
            iconComponent={(
              <NeueMitteilungIcon key="msg-icon" />
            )}
            countAusgang={countAusgang}
            countEingang={countEingang}
            requestError={requestError}
            countZurueckgewiesenAusgang={countZurueckgewiesenAusgang}
            countUebertragungFehlerAusgang={countUebertragungFehlerAusgang}
          />
          <NavigationGroup
            key="nav-group"
            items={helpItems}
            header="Hilfe & Konfiguration"
            isBurgerMenu={isBurgerMenu}
            iconComponent={(
              <InformationIcon key="info-icon" />
            )}
          />
          <NavigationGroup
            key="nav-verlinkung"
            header="Anwendungen"
            items={verlinkungItems}
            isBurgerMenu={isBurgerMenu}
            iconComponent={(
              <VerlinkungIcon key="verlinkung-icon" className="verlinkung-icon" />
            )}
          />
          <Polling
            key="polling-schriftsaetze"
            action={this.handleCountSchriftverkehrStatus}
            duration={getPollingTimeCountAusgangEingang()}
          />
        </div>
        <div className="navbar mt-3">
          <ErledigungenStatus
            key="polling-btn"
            erledigungenStatus={erledigungenStatus}
            onClick={this.handleClickErledigungenStatusButton}
          />
        </div>
      </>
    );
  }
}

Navigation.defaultProps = {
  auth: null,
  actions: null,
  isAdmin: false,
  onClick: () => {},
  isBurgerMenu: false,
  appAuthorized: false,
  canKontaktImport: false,
  canOpenAPISpezifikation: false,
};

Navigation.propTypes = {
  auth: PropTypes.object,
  onClick: PropTypes.func,
  actions: PropTypes.object,
  isBurgerMenu: PropTypes.bool,
  appAuthorized: PropTypes.bool,
  canKontaktImport: PropTypes.bool,
  history: PropTypes.object.isRequired,
  canOpenAPISpezifikation: PropTypes.bool,
  isAdmin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export { Navigation as NavigationTest };

/**
 * mapStateToProps function
 * @param {Object} state state
 * @returns {Object} mapped state to props
 */
function mapStateToProps(state) {
  return {
    ...state,
  };
}

/**
 * mapDispatchToProps
 * @param {Object} dispatch dispatch
 * @return {Object} redux object
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      storeFirstBreadcrumb,
      clearAllTableLocationSearch,
      clearAllTableExtraSearchParam,
      clearExekutionsantragBasisdaten,
    }, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
