import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import APIRedoc from './APIRedoc';
import PATH from '../constants/path.json';

/**
 * function
 * @param {Object} props props
 * @return {JSX.Element} JSX
 * @constructor
 */
function APIRoutes(props) {
  const { match } = props;
  return (
    <Suspense fallback={<div />}>
      <Switch>

        <Route path={`${match.url}${PATH.API_DOC_V001.value}${PATH.API_DOC_DE.value}`} component={APIRedoc} />
        <Route path={`${match.url}${PATH.API_DOC_V001.value}${PATH.API_DOC_EN.value}`} component={APIRedoc} />

        <Route path={`${match.url}${PATH.API_DOC_V002.value}${PATH.API_DOC_DE.value}`} component={APIRedoc} />
        <Route path={`${match.url}${PATH.API_DOC_V002.value}${PATH.API_DOC_EN.value}`} component={APIRedoc} />

        <Route path={`${match.url}${PATH.API_DOC_V100.value}${PATH.API_DOC_DE.value}`} component={APIRedoc} />
        <Route path={`${match.url}${PATH.API_DOC_V100.value}${PATH.API_DOC_EN.value}`} component={APIRedoc} />

        <Redirect from="*" to="/" />

      </Switch>
    </Suspense>
  );
}

APIRoutes.defaultProps = {};

APIRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(APIRoutes);
