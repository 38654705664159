import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';

/**
 * Layout component for non application/not authorized area (landing page and so on)
 * @param {Object} props props
 * @constructor
 */
function NonAppWindowLayout(props) {
  const { isOpenAPI } = props;

  return (
    <>
      {!isOpenAPI && (
        <Header />
      )}
      <div className="content">
        {props.children}
      </div>
    </>
  );
}

NonAppWindowLayout.defaultProps = {
  children: null,
  isOpenAPI: false,
};

NonAppWindowLayout.propTypes = {
  children: PropTypes.node,
  isOpenAPI: PropTypes.bool,
};

export default NonAppWindowLayout;
