import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { RedocStandalone } from 'redoc';
import yaml from 'js-yaml';
import PATH from '../constants/path.json';
import { API_V_0_0_1_DE } from '../constants/yaml/V001/api_v_0_0_1_de';
import { API_V_0_0_1_EN } from '../constants/yaml/V001/api_v_0_0_1_en';
import { API_V_0_0_2_DE } from '../constants/yaml/V002/api_v_0_0_2_de';
import { API_V_0_0_2_EN } from '../constants/yaml/V002/api_v_0_0_2_en';
import { API_V_1_0_0_EN } from '../constants/yaml/V100/api_v_1_0_0_en';
import { API_V_1_0_0_DE } from '../constants/yaml/V100/api_v_1_0_0_de';

/**
 * component
 */
class APIRedoc extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Loads and parses a YAML file based on the provided API version.
   *
   * @param {Object} location - The location object containing state with the API version.
   * @param {Object} location.state - The state object that may include the API version.
   * @param {string} location.state.apiVersion - The specific API version to determine the YAML to load.
   * @return {Object|undefined} The parsed YAML object if the API version is recognized; otherwise, undefined.
   */
  loadApiYaml(location) { // eslint-disable-line
    let api = API_V_0_0_1_DE;
    const pathname = location.pathname;

    if (pathname.includes(PATH.API_DOC_V001.value)) {
      api = pathname.includes(PATH.API_DOC_DE.value) ? API_V_0_0_1_DE : API_V_0_0_1_EN;
    }

    if (pathname.includes(PATH.API_DOC_V002.value)) {
      api = pathname.includes(PATH.API_DOC_DE.value) ? API_V_0_0_2_DE : API_V_0_0_2_EN;
    }

    if (pathname.includes(PATH.API_DOC_V100.value)) {
      api = pathname.includes(PATH.API_DOC_DE.value) ? API_V_1_0_0_DE : API_V_1_0_0_EN;
    }

    return yaml.load(api);
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const { location } = this.props;

    return (
      <div>
        <RedocStandalone
          id="redoc"
          key="redoc"
          specUrl={JSON.parse(JSON.stringify(this.loadApiYaml(location)))}
          options={{
            nativeScrollbars: false,
            lazyRendering: true,
          }}
        />
      </div>
    );
  }
}

APIRedoc.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(APIRedoc);
