export const API_V_0_0_2_DE = `
openapi: 3.0.3
info:
  title: webERV Software API Spezifikation
  description: |-
    Dies ist die webERV Software API Spezifikation.
    <br/><br/>
    Die Aufrufe sind zustandslos und entsprechen dem Request-Response-Pattern. Die webERV Software (API) ist als REST-Webservice implementiert. Attachments werden als Bodyparts eines HTTP-Multipart-Requests übermittelt.
    <br/><br/>
    <strong>Manz Hotline</strong>
    <br/>
    Tele: +43 1 531 61 11
    <br/>
    E-Mail: hotline@manz.at
  version: v0.0.2
servers:
  - url: https://qs-weberv.manz.at/__api/
    description: Test System
  - url: https://weberv.manz.at/__api/
    description: Live System
tags:
  - name: 1) Authentifizierung
    description: Authentifiziert den API-Benutzer (bzw. die übergebende Software) gegenüber dem API - Service.
      <br/><br/>
      <strong>1.1) Verschlüsselung</strong>
      <br/>
      Die webERV Software-Schnittstellen sind ausschließlich über eine sichere Verbindung (TLS) erreichbar. Als Transport Layer Security (TLS) Protokoll wird mindestens TLS-Version 1.2 verwendet.
      <br/><br/>
      <strong>1.2) Authentifizierung</strong>
      <br/>
      Die Authentifizierung eines API-Benutzers erfolgt durch das im HTTP-Request übermittelte Client-Zertifikat. Das Client-Zertifikat muss im ersten Schritt von MANZ im System der webERV Software hinterlegt werden. Für die Übergabe der Kontakt- und Schriftsatzdaten, sowie den Dokumententransfer soll dasselbe Client-Zertifikat verwendet werden.
      <br/><br/>
      <strong >
      Im HTTP-Request müssen die Software-Hersteller-ID (als ein HTTP-Parameter "X_SW_ID" im HTTP-Header) und das SSL-Zertifikat (Client-Zertifikat) übermittelt werden.
      </strong>
      <br/><br/>
      <strong>1.3) Autorisierung</strong>
      <br/>
      Jeder authentifizierte Teilnehmer, der für die Nutzung der webERV Software-Schnittstellen autorisiert ist.
  - name: 2) Kontakt
    description: Kontakt suchen / erstellen / aktualisieren / löschen
  - name: 3) Schriftsatz
  - name: 4) Beteiligter
  - name: 5) Dokument
    description: In Arbeit...
paths:
  /external/kontakte:
    get:
      tags:
        - 2) Kontakt
      summary: Kontakte suchen
      description: Nach den Attributen "kuerzel", "kontaktType", "vorname", "nachname", "strasse", "plz", "ort" oder "geburtsdatum"  wird die Liste der Kontakte, die vom Endpoint erstellt wurden, durchsucht.
      operationId: findKontakte
      parameters:
        - name: kuerzel
          in: query
          description:
            Eindeutige Kennung eines Kontaktes mit einer maximalen Länge von 16 Zeichen. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert.
          required: false
          schema:
            type: string
            maximum: 16
          example: MaxMus
        - name: kontaktType
          in: query
          required: false
          schema:
            type: string
            enum:
              - NATUERLICH
              - JURISTISCH
              - UNBEKANNT
          example: NATUERLICH
        - name: vorname
          in: query
          required: false
          description: Vorname der natürlichen Person oder Name2 der juritischen Person, maximal 250 Zeichen lang.
          schema:
            type: string
            maximum: 250
          example: Max
        - name: nachname
          in: query
          required: false
          description: Nachname der natürlichen Person oder Name1 der juritischen Person, maximal 250 Zeichen lang.
          schema:
            type: string
            maximum: 250
          example: Mustermann
        - name: strasse
          in: query
          required: false
          description: Strasse des Kontaktes, maximal 100 Zeichen lang.
          schema:
            type: string
            maximum: 100
          example: Teststrasse
        - name: plz
          in: query
          required: false
          description: PLZ des Kontaktes, maximal 10 Zeichen lang.
          schema:
            type: string
            maximum: 10
          example: 1010
        - name: ort
          in: query
          required: false
          description: Ort des Kontaktes, maximal 100 Zeichen lang.
          schema:
            type: string
            maximum: 100
          example: Wien
        - name: geburtsdatum
          in: query
          required: false
          description: Geburtsdatum des Kontaktes. (nur bei natürliche Personen)
          schema:
            type: date
          example: 1970-01-01
      responses:
        200:
          description: Liefert eine Liste mit Kontakt-Übersichten
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: "#/components/schemas/QueryKontakteResponse"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    post:
      tags:
        - 2) Kontakt
      summary: Erstellen eines Kontaktes
      description: Erstellung eines Kontaktes
      operationId: createKontakt
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/KontaktDTO"
      responses:
        200:
          description: Ergebnis der Erstellung des Kontaktes
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/KontaktDTO"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    put:
      tags:
        - 2) Kontakt
      summary: Aktualisierung eines Kontaktes
      description: Aktualisierung eines Kontaktes
      operationId: updateKontakt
      parameters:
        - name: kuerzel
          in: path
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          required: true
          schema:
            type: string
            maximum: 16
          example: MaxMus
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/KontaktDTO"
      responses:
        200:
          description: Ergebnis der Aktualisierung des Kontaktes
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/KontaktDTO"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    delete:
      tags:
        - 2) Kontakt
      summary: Löschung eines Kontaktes
      description: Löschung eines Kontaktes
      operationId: deleteKontakt
      parameters:
        - name: kuerzel
          in: path
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          required: true
          schema:
            type: string
            maximum: 16
          example: MaxMus
      responses:
        204:
          description: Not Content
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
  /external/schriftsaetze:
    post:
      tags:
        - 3) Schriftsatz
      summary: Erstellen eines Schriftsatzes
      description: Bei der Erstellung eines Schriftsatzes müssen die Attributen "applikationsTyp", "advmCode", "aktenzeichen" und  "inhaltArt" in Objekt "inhalt" eingegeben werden.
      operationId: createSchriftsatz
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/SchriftsatzDTO"
      responses:
        200:
          description: Ergebnis der Erstellung des Kontaktes
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/SchriftsatzDTO"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
  /external/schriftsaetze/beteiligte:
    post:
      tags:
        - 4) Beteiligter
      summary: Erstellen eines Beteiligten
      description: Erstellen eines Beteiligten bzw. einer Partei eines Schriftsatze, bei der Erstellung eines Beteiligten müssen die Attribute "rolle" und "kontaktKuerzel" eingegeben werden.
      operationId: createBeteiligter
      parameters:
        - name: schriftsatzId
          in: path
          description:
            Eindeutige Kennung eines Schriftsatze. Dies wird bei der Schriftsatz-Erstellung eines Schriftsatze in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "schriftsatzId" nicht geändert (read-only) werden!
          required: true
          schema:
            type: number
          example: 123456
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/BeteiligterDTO"
      responses:
        200:
          description: Ergebnis der Erstellung des Kontaktes
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/BeteiligterDTO"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
components:
  schemas:
    QueryKontakteResponse:
      type: object
      properties:
        kontaktType:
          type: string
          nullable: false
          enum:
            - NATUERLICH
            - JURISTISCH
            - UNBEKANNT
          example: JURISTISCH
        kuerzel:
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          type: string
          nullable: false
          maximum: 16
          example: MaxMus
        vorname:
          description: Vorname der natürlichen Person bzw. Name2 der juristischen Personen.
          type: string
          nullable: true
          maximum: 250
          example: Max
        nachname:
          description: Nachname der natürlichen Person bzw. Name1 der juristischen Personen.
          type: string
          nullable: false
          maximum: 250
          example: Mustermann
    ErrorResponse:
      type: object
      properties:
        errorCode:
          type: string
          description: Fehlercode
          nullable: true
        errorDetailCode:
          type: array
          description: Fehlerdetails
          nullable: true
          items:
            $ref: "#/components/schemas/ErrorDetailCode"
        errorCategory:
          type: string
          description: Fehler Kategorie
          nullable: true
          enum:
            - CRASH
            - ERROR
            - WARNING
            - HIN
        timestamp:
          type: integer
          description: Zeitpunkt (UNIX Timestamp mit Millisekunden).
          nullable: true
          format: int64
          example: 2025-01-23T09:52:22.189874Z
        logNr:
          type: UUID
          description: UUID des Logs Fehlers
          nullable: true
        logMessage:
          type: string
          description: Logs der Fehlermeldung
          nullable: true
        exceptionName:
          type: string
          description: Exception Name im Log
          nullable: true
    ErrorDetailCode:
      type: object
      properties:
        id:
          type: string
          description: Fehlerdetail ID
          nullable: true
        params:
          type: object
          additionalProperties:
            type: object
            nullable: true
            properties:
              key:
                type: integer
              value:
                type: string
      description: Liste an weiteren Fehlermeldungen.
      nullable: true
    KontaktDTO:
      type: object
      properties:
        kontaktType:
          type: string
          nullable: false
          enum:
            - NATUERLICH
            - JURISTISCH
            - UNBEKANNT
          example: JURISTISCH
        kuerzel:
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          type: string
          nullable: true
          maximum: 16
          example: MaxMus
        affix:
          description: Affix bzw. Titel vorgestellt des Kontaktes. (nur natürliche Person)
          type: string
          nullable: true
          maximum: 37
          example: Dr.
        suffix:
          description: Suffix bzw. Titel nachgestellt des Kontaktes. (nur natürliche Person)
          type: string
          nullable: true
          maximum: 37
          example: Bakk.
        beruf:
          description: Beruf des Kontaktes. (nur juristische Person)
          type: string
          nullable: true
          maximum: 37
          example: Rechtsanwalt
        advmCode:
          description: AdvmCode des Kontaktes (z.B. R123456).
          pattern: "([a-zA-Z][0-9]{6})|([a-zA-Z]{2}[0-9]{5})"
          type: string
          nullable: true
          maximum: 7
          example: A123456
        name1:
          description: Nachname der natürlichen Person oder Name1 der juristischen Personen.
          type: string
          nullable: false
          maximum: 250
          example: Mustermann
        name2:
          description: Vorname der natürlichen Person oder Name2 der juristischen Personen.
          type: string
          nullable: true
          maximum: 250
          example: Max
        staatIsoAlpha3:
          description: ISO Alpha-3-Ländercodes der Nationalität (nur natürliche Person)
          type: string
          nullable: true
          maximum: 3
          example: AUT
        staatStatus:
          description: Nationalität Status (nur natürliche Person)
          nullable: true
          enum:
            - STAATENLOS
            - UNGEKLAERT
            - GEKLAERT
            - UNBEKANNT
          example: GEKLAERT
        herkunftslandIsoAlpha3:
          description: ISO Alpha-3-Ländercodes des Firmensitz (nur juristische Person)
          type: string
          nullable: true
          maximum: 3
          example: AUT
        asylHerkunftsland:
          description: Herkunftsland der natürlichen Person (nur natürliche Person)
          type: string
          nullable: true
          maximum: 37
          example: Deutschland
        geschlecht:
          description: Geschlecht der natürlichen Person (nur natürliche Person)
          nullable: true
          enum:
            - MAENNLICH
            - WEIBLICH
            - UNBEKANNT
          default: UNBEKANNT
          example: MAENNLICH
        geburtsdatum:
          description: Geburtsdatum des Kontaktes. (nur natürliche Person)
          nullable: true
          type: date
          example: 1970-01-01
        registerType:
          description: Registertype der juristischen Person (nur juristische Person)
          nullable: true
          enum:
            - FIRMENBUCH
            - VEREINSREGISTER
            - SONSTIGE
            - GEWERBEREGISTER
            - ERGAENZUNGSREGISTER_FUER_SONSTIGE_BETROFFENE
          example: FIRMENBUCH
        registerNummer:
          description: Registernummer der juristischen Person (nur juristische Person)
          type: string
          nullable: true
          maximum: 32
          example: w123456
        notizen:
          description: Notizen zum Kontakt
          type: string
          nullable: true
          maximum: 8192
        adresse:
          $ref: "#/components/schemas/AdresseDTO"
        kontaktDetailList:
          type: array
          description: Kontaktdaten z.B. E-Mail, Telefon, Webseite usw.
          items:
            $ref: "#/components/schemas/KontaktDetailDTO"
        kontoList:
          type: array
          description: Bankkonten des Kontaktes
          items:
            $ref: "#/components/schemas/KontoDTO"
    AdresseDTO:
      type: object
      properties:
        lkz:
          description: ISO Alpha-3-Ländercodes der Land
          type: string
          nullable: false
          maximum: 3
          example: AUT
        ort:
          description: Ort
          type: string
          nullable: false
          maximum: 100
          example: Wien
        plz:
          description: Postleitzahl (Abk. PLZ)
          type: string
          nullable: false
          maximum: 10
          example: 1010
        strasse:
          description: Straßennamen
          type: string
          nullable: false
          maximum: 100
          example: Musterstrasse
        nr:
          description: Straßennummer
          type: string
          nullable: false
          maximum: 50
          example: 2
    KontaktDetailDTO:
      type: object
      properties:
        kontaktDetailType:
          description: Kontaktdetailstype
          nullable: false
          enum:
            - ASYLNAME
            - TELEFON
            - FAX
            - EMAIL
            - WEBSEITE
            - MOBIL
          example: WEBSEITE
        daten:
          description: Inhalt des Details
          type: string
          nullable: false
          maximum: 80
          example: https://weberv.manz.at/home
        bemerkung:
          description: Inhalt des Details
          type: string
          nullable: true
          maximum: 128
          example: webERV Software
    KontoDTO:
      type: object
      properties:
        kontoType:
          type: string
          description: Bankkontotype
          nullable: false
          enum:
            - GEBUEHRENEINZUG
            - ZAHLUNGEN
            - SONSTIGE
          example: GEBUEHRENEINZUG
        bezeichnung:
          description: Kontobezeichnung
          type: string
          nullable: true
          maximum: 32
        iban:
          description: IBAN - International Bank Account Number
          type: string
          nullable: false
          maximum: 34
          example: AT772011100123456789
        swift:
          description: Swift - Bank Identifier Code
          type: string
          nullable: true
          maximum: 11
          example: RZTIAT22263
        iso3Lc:
          description: ISO Alpha-3-Ländercodes der Land
          type: string
          nullable: true
          maximum: 3
          example: AUT
        blz:
          description: BLZ
          type: string
          nullable: true
          maximum: 35
          example: 123456
    SchriftsatzDTO:
      type: object
      properties:
        id:
          type: string
          description:
            <br/>
            Die "id" des Schriftsatzes wird bei der Erstellung eines Schriftsatzes in der webERV Software automatisch generiert und zurückgeliefert.
            <br/>
            Nach der Erstellung sollte die "id" nicht geändert werden (read-only)!
            <br/>
            <br/>
            <strong>
            Bei der Erstellung der Beteiligten und der Dokumente eines Schriftsatzes muss die "id" vorhanden sein und erneut als eindeutige Kennung/Identifikation übermittelt werden!
            </strong>
          nullable: false
        applikationsTyp:
          type: string
          nullable: false
          enum:
            - BW
            - TLDZ
            - VJ
            - STA
            - UNKNOWN
          description:
            <br/>
            Applikationstyp eines Schriftsatzes
            <br/>
            Beschreibung der Applikationstypen&#58;
            <table>
            <thead>
            <tr>
            <td>Applikationstyp</td>
            <td>Beschreibung</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>BW</td>
            <td>Bundesverwaltungsgerichtshof</td>
            </tr>
            <tr>
            <td>TLDZ</td>
            <td>Teilnehmerdirektzustellung</td>
            </tr>
            <tr>
            <td>VJ</td>
            <td>Verfahrensautomation der Justiz</td>
            </tr>
            <tr>
            <td>STA</td>
            <td>Staatsanwaltschaft</td>
            </tr>
            <tr>
            <td>UNKNOWN</td>
            <td>Undefinierter Anwendungstyp</td>
            </tr>
            </tbody>
            </table>
          example: BW
        advmCode:
          description: AdvmCode (z.B. R123456) des Einbringers/webERV Software Profils.
            <br/>
            <strong>
            Dieser AdvmCode muss in der webERV Software bereits vorhanden sein!
            </strong>
          pattern: "([a-zA-Z]|JB)[0-9]*"
          type: string
          nullable: false
          maximum: 7
          example: A123456
        aktenzeichen:
          description: Internes Aktenzeichen des Schriftsatzes
          type: string
          nullable: false
          maximum: 25
          example: ABCnr. XY123456
        inhalt:
          description:
            <strong>
            Die Schriftsatztart oder auch der SchriftsatzInhalt laut der "inhaltArt".
            </strong>
            <br/>
            <br/>
            Die Beschreibung der "inhaltArten" finden sie in der folgenden Tabelle&#58;
            <table>
            <thead>
            <tr>
            <td>inhaltArt</td>
            <td>Beschreibung</td>
            <td>Applikationstyp</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>VF_ASYLGH</td>
            <td>Bundesverwaltungsgericht</td>
            <td>BW</td>
            </tr>
            <tr>
            <td>TLDZ</td>
            <td>Teilnehmerdirektzustellung</td>
            <td>TLDZ</td>
            </tr>
            <tr>
            <td>VJ_Serst</td>
            <td>Sonstige Ersteingabe</td>
            <td>VJ</td>
            </tr>
            <tr>
            <td>VJ_Folge</td>
            <td>Sonstige Folgeeingabe</td>
            <td>VJ</td>
            </tr>
            <tr>
            <td>STA_Eingabe</td>
            <td>Eingabe an die Staatsanwaltschaften</td>
            <td>STA</td>
            </tr>
            <tr>
            <td>UNKNOWN</td>
            <td>unbekannter Inhalt</td>
            <td>UNKNOWN</td>
            </tr>
            </tbody>
            </table>
          oneOf:
            - $ref: "#/components/schemas/BundesverwaltungsgerichtshofDTO"
            - $ref: "#/components/schemas/DirektzustellungDTO"
            - $ref: "#/components/schemas/SonstigeErsteingabeDTO"
            - $ref: "#/components/schemas/SonstigeFolgeeingabeDTO"
            - $ref: "#/components/schemas/EingabeAnStaatsanwaltschaftenDTO"
    BundesverwaltungsgerichtshofDTO:
      type: object
      description: Bundesverwaltungsgericht
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "VF_ASYLGH" muss für Bundesverwaltungsgericht eingegeben werden!
            </strong>
          nullable: false
          enum:
            - VF_ASYLGH
          example: VF_ASYLGH
        gebuehrenIndikator:
          type: string
          description: Gebühren
          nullable: true
          enum:
            - GESETZLICH_BEFREIT
            - JA
            - NEIN
          example: GEBUEHRENEINZUG
    DirektzustellungDTO:
      type: object
      description: Direktzustellung
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "TLDZ" muss für Direktzustellung eingegeben werden!
            </strong>
          nullable: false
          enum:
            - TLDZ
          example: TLDZ
        mitteilung:
          type: string
          description: Mitteilung
          nullable: true
    SonstigeErsteingabeDTO:
      type: object
      description: Sonstige Ersteingabe
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "VJ_Serst" muss für Sonstige Ersteingabe eingegeben werden!
            </strong>
          nullable: false
          enum:
            - VJ_Serst
          example: VJ_Serst
        gericht:
          type: string
          description: Gericht, z.B. "001" (Bezirksgericht Innere Stadt Wien)
          pattern: "([0-9]{3}|S[0-9][A-Z])"
          nullable: true
          example: 001
        anzahlAusfertigungen:
          type: string
          description: Anzahl der Ausfertigungen, maximal 3-fach
          nullable: true
          minimum: 1
          maximum: 3
        streitwertBeschreibung:
          type: string
          description: Beschreibung zum Streitwert, geben Sie zusätzlich zum Fallcode einen Text ein. (150 Zeichen)
          nullable: true
          maximum: 150
        streitwert:
          type: number
          format: double
          description: Streitwert
          nullable: true
          minimum: 0.00
          maximum: 99999999999.99
        geschaeftszahl:
          $ref: "#/components/schemas/GeschaeftszahlDTO"
    SonstigeFolgeeingabeDTO:
      type: object
      description: Sonstige Folgeeingabe
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "VJ_Folge" muss für Sonstige Folgeeingabe eingegeben werden!
            </strong>
          nullable: false
          enum:
            - VJ_Folge
          example: VJ_Folge
        gericht:
          type: string
          description: Gericht, z.B. "001" (Bezirksgericht Innere Stadt Wien)
          pattern: "([0-9]{3}|S[0-9][A-Z])"
          nullable: true
          example: 001
        anzahlAusfertigungen:
          type: string
          description: Anzahl der Ausfertigungen, maximal 3-fach
          nullable: true
          minimum: 1
          maximum: 3
        streitwertBeschreibung:
          type: string
          description: Beschreibung zum Streitwert, geben Sie zusätzlich zum Fallcode einen Text ein. (150 Zeichen)
          nullable: true
          maximum: 150
        geschaeftszahl:
          $ref: "#/components/schemas/GeschaeftszahlDTO"
    EingabeAnStaatsanwaltschaftenDTO:
      type: object
      description: Eingabe an die Staatsanwaltschaften
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "STA_Eingabe" muss für Eingabe an die Staatsanwaltschaften eingegeben werden!
            </strong>
          nullable: false
          enum:
            - STA_Eingabe
          example: STA_Eingabe
        gericht:
          type: string
          description: Gericht, z.B. "001" (Bezirksgericht Innere Stadt Wien)
          pattern: "([0-9]{3}|S[0-9][A-Z])"
          nullable: true
          example: 001
        betreff:
          type: string
          description: Betreff
          nullable: true
          maximum: 100
    GeschaeftszahlDTO:
      type: object
      description: Geschäftszahl
      properties:
        abteilungsNummer:
          type: number
          description: Abteilungsnummer (muss 3-stellig sein; z.B&#58; Abt. "5" Sie geben "005" ein)
          nullable: false
          maximum: 3
          example: 005
        gattung:
          type: string
          description: Gattung
          nullable: false
          example: A
        laufendeNummer:
          type: number
          description: Laufende Nummer (kann 5 Zeichen haben)
          nullable: false
          minimum: 1
          maximum: 999999
          example: 22222
        jahr:
          type: number
          description: Jahr (muss 4-stellig sein z.B. 2021)
          nullable: false
          minimum: 1920
          maximum: 9999
          example: 2001
        pruefKennzeichen:
          type: string
          description: Prüfkennzahl (1 Zeichen, Groß-/Kleinschreibung beachten)
          nullable: false
          minimum: 1
          maximum: 1
          example: m
    BeteiligterDTO:
      type: object
      description: Beteiligten Objekt
      properties:
        rolle:
          type: string
          description: Die erlaubten "Beteiligtenrollen" einer beteiligten Partei eines Schriftsatzes (Beteiligter) sind für die jeweiligen "inhaltArten" (Schriftsatzarten) in der folgenden Tabelle beschrieben.
            <br/>
            <br/>
            Beschreibung der Beteiligtenrolle&#58;
            <table>
            <thead>
            <tr>
            <td>Beteiligtenrollen</td>
            <td>Beschreibung</td>
            <td>"inhaltArten" (Schriftsatzarten)</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>ANTRAGSTELLER</td>
            <td>Antragsteller</td>
            <td>VF_ASYLGH, TLDZ</td>
            </tr>
            <tr>
            <td>ANTRAGSGEGNER</td>
            <td>Antragsgegner</td>
            <td>VF_ASYLGH, TLDZ</td>
            </tr>
            <tr>
            <td>MITBETEILIGTE_PARTEI</td>
            <td>Mitbeteiligte Partei</td>
            <td>VF_ASYLGH, TLDZ</td>
            </tr>
            <tr>
            <td>ERSTE_PARTEI</td>
            <td>Erste Partei</td>
            <td>VJ_Serst</td>
            </tr>
            <tr>
            <td>ZWEITE_PARTEI</td>
            <td>Zweite Partei</td>
            <td>VJ_Serst</td>
            </tr>
            <tr>
            <td>SONSTIGER_BETEILIGTER</td>
            <td>Sonstiger Beteiligter</td>
            <td>VJ_Folge</td>
            </tr>
            </tbody>
            </table>
          nullable: false
          enum:
            - ANTRAGSTELLER
            - ANTRAGSGEGNER
            - MITBETEILIGTE_PARTEI
            - ERSTE_PARTEI
            - ZWEITE_PARTEI
            - SONSTIGER_BETEILIGTER
          example: ANTRAGSTELLER
        rollenbeschreibung:
          type: string
          description: Die Rollenbeschreibung darf nur für die inhaltArt "VJ_Folge" verwendet werden.
          nullable: true
          example: 1. Kläger
        kontaktKuerzel:
          type: string
          description: Kontakt-Kürzel des Beteiligten/einer Beteiligten Partei; der entsprechende Kontakt muss in der webERV Software bereits vorhanden sein.
          nullable: false
          example: MaxMu
        vertreterKontaktKuerzel:
          type: string
          description: Kontakt-Kürzel eines Vertreters (z.B. AdvmCode des Vertreters; z.B. R123456) des Beteiligten; der entsprechende Kontakt muss in der webERV Software bereits vorhanden sein.
          nullable: true
          example: R123456
        vertreterType:
          type: string
          description: Vertretertyp eines Vertreters des Beteiligten, wenn das Attribut "vertreterKontaktKuerzel" befüllt wird, muss der "vertreterType" auch befüllt werden.
            <br/>
            <br/>
            Beschreibung der VertreterTypen&#58;
            <table>
            <thead>
            <tr>
            <td>VertreterType</td>
            <td>"inhaltArten" (Schriftsatzarten)</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>EINBRINGER</td>
            <td>VF_ASYLGH, VJ_Serst (nur für BeteiligtenRolle "ERSTE_PARTEI"), VJ_Folge</td>
            </tr>
            <tr>
            <td>RECHTSANWALT</td>
            <td>VF_ASYLGH</td>
            </tr>
            <tr>
            <td>SONSTIGER</td>
            <td>VF_ASYLGH, TLDZ, VJ_Serst, VJ_Folge</td>
            </tr>
            </tbody>
            </table>
          nullable: true
          enum:
            - EINBRINGER
            - RECHTSANWALT
            - SONSTIGER
          example: SONSTIGER
        vertreterDirektzustellung:
          type: boolean
          description: Vertreter - TLDZ. gem. §112 ZPO soll zugestellt werden; Dies Feld muss nicht befüllt werden
            <br/>
            Als Defaultwert wird automatisch "true" übergeben. Es kann jedoch auch mit "false" übersteuert werden.
          nullable: true
          example: true
          default: true

`;
