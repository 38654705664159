import APP_TYPE from '../schriftsatz.application.type.json';
import { config } from '../../config';
import { ERVBOX_LEGAL_DOK_FORMAT, PDF_FORMAT } from './file.types';

/**
 * get default timeout time
 * @return {number} default timeout time
 */
export function getDefalutTimeout() {
  const timeout = config.timeout.timeout_default;
  return (timeout && parseInt(timeout, 10)) || 60000;
}

/**
 * get long timeout time
 * @return {number} long timeout time
 */
export function getWaitingLoginTimeout() {
  const timeout = config.timeout.timeout_waiting_login;
  return (timeout && parseInt(timeout, 10)) || 30000;
}

/**
 * get schriftsatz validate timeout time
 * @return {number} long timeout time
 */
export function getSchriftsatzValidateTimeout() {
  const timeout = config.timeout.timeout_schriftsatz_validate;
  return (timeout && parseInt(timeout, 10)) || 60000;
}

/**
 * send schriftsatz validate timeout time
 * @return {number} long timeout time
 */
export function getSchriftsatzSendenTimeout() {
  const timeout = config.timeout.timeout_schriftsatz_senden;
  return (timeout && parseInt(timeout, 10)) || 120000;
}

/**
 * get file upload timeout time
 * @return {number} long timeout time
 */
export function getFileUploadTimeout() {
  const timeout = config.timeout.timeout_file_upload;
  return (timeout && parseInt(timeout, 10)) || 300000;
}

/**
 * get eingang abholen timeout time
 * @return {number} long timeout time
 */
export function getEingangAbholenTimeout() {
  const timeout = config.timeout.timeout_eingang_abholen;
  return (timeout && parseInt(timeout, 10)) || 1800000;
}

/**
 * get fb read auszug timeout time
 * @return {number} long timeout time
 */
export function getFbReadAuszugTimeout() {
  const timeout = config.timeout.timeout_fb_read_auszug;
  return (timeout && parseInt(timeout, 10)) || 120000;
}

/**
 * get fb read auszug timeout time
 * @return {number} long timeout time
 */
export function getFbVisualisierungTimeout() {
  const timeout = config.timeout.timeout_fb_visualisierung;
  return (timeout && parseInt(timeout, 10)) || 120000;
}

/**
 * get polling time of count ausgang or eingang
 * @return {number} polling time
 */
export function getPollingTimeCountAusgangEingang() {
  const pollingTime = config.polling.polling_time_count_ausgang_eingang;
  return (pollingTime && parseInt(pollingTime, 10)) || 60;
}

/**
 * get polling time of progress bar
 * @return {number} polling time
 */
export function getPollingTimeProgressBar() {
  const pollingTime = config.polling.polling_time_progress_bar;
  return (pollingTime && parseInt(pollingTime, 10)) || 180;
}

/**
 * get polling time of eingang abholen
 * @return {number} polling time
 */
export function getPollingTimeEingangAbholen() {
  const pollingTime = config.polling.polling_time_eingang_abholen;
  return (pollingTime && parseInt(pollingTime, 10)) || 60;
}

/**
 * getPollingTimeJustizboxUploadStatus
 * @return {*|number|number} undefined
 */
export function getPollingTimeJustizboxUploadStatus() {
  const pollingTime = config.polling.polling_time_justizbox_upload_status;
  return (pollingTime && parseInt(pollingTime, 10)) || 30;
}

/**
 * getPollingTimeErledigungDokumenteDownloadStatus
 * @return {*|number|number} undefined
 */
export function getPollingTimeErledigungDokumenteDownloadStatus() {
  const pollingTime = config.polling.polling_time_erledigung_dokumente_download_status;
  return (pollingTime && parseInt(pollingTime, 10)) || 15;
}

/**
 * getMaxJustizboxSize
 * @param {String} fileType fileType
 * @return {*|number} getMaxJustizboxSize
 */
export function getMaxJustizboxSize(fileType) {
  let maxSize;

  if (fileType && !PDF_FORMAT.includes(fileType) && ERVBOX_LEGAL_DOK_FORMAT.includes(fileType)) {
    maxSize = getMaxJustizboxAudioVideoSize();
  } else {
    maxSize = getMaxJustizboxDefaultSize();
  }

  return {
    maxSize,
  };
}

/**
 * getMaxJustizboxAudioVideoSize
 * @return {*|number} getMaxJustizboxAudioVideoSize
 */
export function getMaxJustizboxAudioVideoSize() {
  return config.justizbox_maxsize.max_justizbox_audio_video || 2147483648;
}

/**
 * getMaxJustizboxDefaultSize
 * @return {*|number} getMaxJustizboxDefaultSize
 */
export function getMaxJustizboxDefaultSize() {
  return config.justizbox_maxsize.max_justizbox_default || 262144000;
}

/**
 * get polling time of justizbox upload
 * @return {number} polling time
 */
export function getPollingTimeJustizboxUpload() {
  const pollingTime = config.polling.polling_time_justizbox_upload;
  return (pollingTime && parseInt(pollingTime, 10)) || 3;
}

/**
 * get max dokument size
 * @param {String} applikationsTyp applikationsTyp
 * @return {number} max dokument size
 */
export function getMaxDokumentSize(applikationsTyp) {
  let maxSize = config.dokument_maxsize.max_dokument_size_default;
  let maxInfoSize = 50;

  if (applikationsTyp) {
    // eslint-disable-next-line default-case
    switch (applikationsTyp) {
      case APP_TYPE.VF:
        maxSize = config.dokument_maxsize.max_dokument_size_vf || 26214400;
        maxInfoSize = 25;
        break;
      case APP_TYPE.VW:
        maxSize = config.dokument_maxsize.max_dokument_size_vw || 26214400;
        maxInfoSize = 25;
        break;
      case APP_TYPE.BW:
        maxSize = config.dokument_maxsize.max_dokument_size_bw || 26214400;
        maxInfoSize = 25;
        break;
      case APP_TYPE.FB:
        maxSize = config.dokument_maxsize.max_dokument_size_fb || 52428800;
        break;
      case APP_TYPE.VJ:
        maxSize = config.dokument_maxsize.max_dokument_size_vj || 52428800;
        break;
      case APP_TYPE.TLDZ:
        maxSize = config.dokument_maxsize.max_dokument_size_tldz || 52428800;
        break;
      case APP_TYPE.STA:
        maxSize = config.dokument_maxsize.max_dokument_size_sta || 52428800;
        break;
      case APP_TYPE.GT:
        maxSize = config.dokument_maxsize.max_dokument_size_gt || 52428800;
        break;
      case APP_TYPE.EUM:
        maxSize = config.dokument_maxsize.max_dokument_size_eum || 12582912;
        maxInfoSize = 12;
        break;
      case APP_TYPE.THB:
        maxSize = config.dokument_maxsize.max_dokument_size_thb || 52428800;
        break;
      case APP_TYPE.EZUSTELLUNG:
        maxSize = config.dokument_maxsize.max_dokument_size_aeub || 52428800;
        break;
      case APP_TYPE.STV:
        maxSize = config.dokument_maxsize.max_dokument_size_stv || 52428800;
        break;
    }
  }

  maxSize = (maxSize && parseInt(maxSize, 10)) || 52428800;

  return {
    maxSize,
    maxInfoSize,
  };
}

/**
 * getJustizBoxAktiv
 * @param {String} applikationsTyp applikationsTyp
 * @return {Boolean} getJustizBoxAktiv
 */
export function allowJustizBoxAktiv(applikationsTyp) {
  let aktiv = config.justizbox.justizbox_aktiv_default || false;

  if (applikationsTyp) {
    // eslint-disable-next-line default-case
    switch (applikationsTyp) {
      case APP_TYPE.VF:
        aktiv = config.justizbox.justizbox_aktiv_vf || false;
        break;
      case APP_TYPE.VW:
        aktiv = config.justizbox.justizbox_aktiv_vw || false;
        break;
      case APP_TYPE.BW:
        aktiv = config.justizbox.justizbox_aktiv_bw || false;
        break;
      case APP_TYPE.FB:
        aktiv = config.justizbox.justizbox_aktiv_fb || false;
        break;
      case APP_TYPE.VJ:
        aktiv = config.justizbox.justizbox_aktiv_vj || false;
        break;
      case APP_TYPE.TLDZ:
        aktiv = config.justizbox.justizbox_aktiv_tldz || false;
        break;
      case APP_TYPE.STA:
        aktiv = config.justizbox.justizbox_aktiv_sta || false;
        break;
      case APP_TYPE.GT:
        aktiv = config.justizbox.justizbox_aktiv_gt || false;
        break;
      case APP_TYPE.EUM:
        aktiv = config.justizbox.justizbox_aktiv_eum || false;
        break;
      case APP_TYPE.THB:
        aktiv = config.justizbox.justizbox_aktiv_thb || false;
        break;
      case APP_TYPE.EZUSTELLUNG:
        aktiv = config.justizbox.max_dokument_size_aeub || false;
        break;
      case APP_TYPE.STV:
        aktiv = config.justizbox.justizbox_aktiv_stv || false;
        break;
    }
  }

  return aktiv;
}

/**
 * allowJustizBoxForZPO112Aktiv
 * @return {*|boolean} allowJustizBoxForZPO112Aktiv
 */
export function allowJustizBoxForZPO112Aktiv() {
  return config.justizbox.justizbox_aktiv_zpo112 || true;
}

/**
 * allowJustizBoxForZPO112Aktiv
 * @param {String} berechtigung berechtigung
 * @return {*|boolean} allowJustizBoxForZPO112Aktiv
 */
export function checkApplicationSwitch(berechtigung) {
  if (berechtigung) {
    const appSwitch = config.application_obliged_switch[berechtigung.toString()];
    if (appSwitch === true || appSwitch === 'true' || appSwitch === 'on') {
      return true;
    }
    if (appSwitch === false || appSwitch === 'false' || appSwitch === 'off') {
      return false;
    }
  }
  return undefined;
}

/**
 * checkOpenAPISwitch
 * @return {*|boolean} allowJustizBoxForZPO112Aktiv
 */
export function checkOpenAPISwitch() {
  const appSwitch = config.open_api_switch;
  if (appSwitch === true || appSwitch === 'true' || appSwitch === 'on') {
    return true;
  }
  if (appSwitch === false || appSwitch === 'false' || appSwitch === 'off') {
    return false;
  }
  return undefined;
}

/**
 * checkOpenInProcessingAPISwitch
 * @return {*|boolean} allowJustizBoxForZPO112Aktiv
 */
export function checkOpenInProcessingAPISwitch() {
  const appSwitch = config.open_api_in_processing_swith;
  if (appSwitch === true || appSwitch === 'true' || appSwitch === 'on') {
    return true;
  }
  if (appSwitch === false || appSwitch === 'false' || appSwitch === 'off') {
    return false;
  }
  return undefined;
}

/**
 * Checks if the Justizbox upload is abortable.
 *
 * @return {boolean} Returns true if the Justizbox upload is abortable, otherwise false.
 */
export function isJustizboxUploadAbortable() {
  const abortable = config.justiz_upload_abortable;
  return abortable === true || abortable === 'true' || abortable === 'on';
}

export const JUSTIZ_BOX_MAX_SIZE = 10737418240;

export const FRIENDLY_CAPTCHA_ENDPOINT = 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle';

export const ALLOW_GB_AUSZUG = true;

export const ALLOW_GB_AUSZUG_XML_PRINT = false;
