import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  AUTH_ROUTES_NO_LAYOUT,
  AUTH_ROUTES_WITH_LAYOUT,
  PrivateRoute,
  withConnectedUserData,
} from '@auth/auth-oidc-integration';
import PropTypes from 'prop-types';
import { withErrorReporter } from '../../common/ErrorReporter/withErrorReporter';
import Layout from '../../Layout';
import PATH from '../../constants/path';
import InfoSite from '../../InfoSite/InfoSite';
import getUserConfirmation from '../../common/Form/getUserConfirmation';
import Ausgang from '../../SendenEmpfangen/Ausgang';
import Eingang from '../../SendenEmpfangen/Eingang';
import Overview from '../../Overview/Overview';
import Home from '../../LandingPage/Home';
import ErledigungRoutes from '../../Erledigung';
import BaseRoute from './BaseRoute';
import Datenimport from '../../Datenimport/Datenimport';
import { arrayHasItem } from '../../utils/general.utils';
import BERECHTIGUNGEN from '../../constants/berechtigungen';
import HelpView from '../../common/Form/HelpView';
import HilfeSupport from '../../HilfeSupport/HilfeSupport';
import FbAuszugHtml from '../../Schriftsatz/FB/components/elements/FbAuszugHtml';
import APIs from '../../OpenAPI/APIs';
import APIRoutes from '../../OpenAPI/APIRoutes';

const KontaktRoutes = React.lazy(() => import('../../Kontakt'));
const SchriftsatzRoutes = React.lazy(() => import('../../Schriftsatz'));
const Konfiguration = React.lazy(() => import('../../Einbringer/Einbringer'));

/**
 * Routes component
 * @returns {Object} jsx component
 */
const Routes = withConnectedUserData(withErrorReporter((props) => {
  // TODO Berechtigung und Admin Logik auf Liste der approutes anwenden
  const appUserObj = props.appUserObj;
  const canKontaktImport = (appUserObj && (arrayHasItem(appUserObj.berechtigungen) && appUserObj.berechtigungen.find((v) => v === BERECHTIGUNGEN.KONTAKTIMPORT))) || false;
  const approutes = props
    ? [
      <PrivateRoute
        path={PATH.KONTAKT.value}
        component={() => <KontaktRoutes canKontaktImport={canKontaktImport} />}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={SchriftsatzRoutes}
        path={PATH.SCHRIFTSATZ.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={ErledigungRoutes}
        path={PATH.ERLEDIGUNG.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={Ausgang}
        path={PATH.AUSGANG.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={Eingang}
        path={PATH.EINGANG.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={Konfiguration}
        path={PATH.KONFIGURATION.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={Datenimport}
        path={PATH.DATEN_IMPORT.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={HilfeSupport}
        path={PATH.HILFE_SUPPORT.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={APIs}
        path={PATH.API.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={APIRoutes}
        path={PATH.API_DOC.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={Overview}
        path={PATH.DASHBOARD.value}
        noAuthLocation={PATH.LANDING_PAGE.value}
      />,
      <PrivateRoute
        component={FbAuszugHtml}
        noAuthLocation={PATH.LANDING_PAGE.value}
        path={PATH.STRUK_FB_AUSZUG_HTML.value}
      />,
    ]
    : [];

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => getUserConfirmation(message, callback)}
    >
      <Switch>
        {AUTH_ROUTES_NO_LAYOUT}
        <Layout>
          <Suspense fallback={<div />}>
            <Switch>
              {AUTH_ROUTES_WITH_LAYOUT}
              <Route exact path={PATH.INFO.value} component={InfoSite} />
              <Route exact path={PATH.HILFE.value} component={HelpView} />
              <Route path={PATH.LANDING_PAGE.value} render={() => <Home appAuthorized={props.appAuthorized} />} />
              {approutes}
              <Route path={PATH.ROOT.value} render={() => <BaseRoute appAuthorized={props.appAuthorized} />} />
            </Switch>
          </Suspense>
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}));

Routes.defaultProps = {
  appAuthorized: false,
  appUserObj: null,
};

Routes.propTypes = {
  appAuthorized: PropTypes.bool,
  appUserObj: PropTypes.object,
};

export default Routes;
