import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import REST from '../../../common/Rest';
import SCHRIFTSATZ_STATUS from '../../../constants/schriftsatz.status';
import getUrl from '../../../common/Rest/rest.utils';
import { handleDisconnectOrServerOrTimeoutError } from '../../../common/Rest/makeRestCall';
import AlertIcon from '../../../common/Icons/AlertIcon';
import AlertRedIcon from '../../../common/Icons/AlertRedIcon';
import { TIMEOUT_TYPE } from '../../../common/Rest/fetchWithTimeout';
import { getInteger } from '../../../utils/general.utils';

/**
 * NavigationGroupStatusCounter component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
class NavigationGroupStatusCounter extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.handleClickAlertIcon = this.handleClickAlertIcon.bind(this);
  }

  /**
   * click alert icon and show the error modal
   * @param {Boolean} isAusgang isAusgang
   * @return {undefined}
   */
  handleClickAlertIcon(isAusgang) { // eslint-disable-line
    const { requestError } = this.state;
    const eingangUrl = getUrl(REST.urls.schriftverkehrEingangCount, {
      erledigungStatus: SCHRIFTSATZ_STATUS.NEU.value,
    });
    const ausgangUrl = getUrl(REST.urls.schriftverkehrAusgangCount, {
      schriftsatzStatus: SCHRIFTSATZ_STATUS.GESENDET.value,
    });
    handleDisconnectOrServerOrTimeoutError(
      requestError,
      isAusgang ? ausgangUrl : eingangUrl,
      TIMEOUT_TYPE.LOGIN,
    );
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    const {
      countAusgang,
      countEingang,
      requestError,
      countZurueckgewiesenAusgang,
      countUebertragungFehlerAusgang,
      items,
      header,
      isBurgerMenu,
      iconComponent,
    } = this.props;

    const countZGandUFAusgang = getInteger(countZurueckgewiesenAusgang) + getInteger(countUebertragungFehlerAusgang);

    const linkItems = items.map((item) => {
      const isAusgang = item.label === 'Ausgang';

      const count = getInteger(isAusgang ? countAusgang : countEingang);

      let badgeClassName = 'badge-schriftsatz-gesendet';
      if (count > 0) {
        badgeClassName = (isAusgang && 'badge-schriftsatz-gesendet') || 'badge-schriftsatz-ungelesen';
      } else if (count === 0) {
        badgeClassName = 'badge-schriftsatz-default';
      }

      const showAlertIcon = !!(requestError);
      const showAlertRedIcon = !showAlertIcon && !!(isAusgang && countZGandUFAusgang && countZGandUFAusgang > 0);

      return (
        <li key={item.label}>
          <NavLink
            exact
            to={item.link}
            onClick={item.click}
            aria-label={item.label}
            id={isBurgerMenu ? `${item.link}-burger-menu` : item.link}
            className={(!item.disabled || item.disabled === false) ? '' : 'manz-disabled'}
          >
            <div className="flex-space-between">
              <span>{`${item.label}`}</span>
              {showAlertIcon && (
                <AlertIcon
                  onClick={() => this.handleClickAlertIcon(isAusgang)}
                  title="Es besteht keine Internetverbindung - bitte stellen Sie sicher, dass Ihr Computer korrekt angeschlossen ist!"
                />
              )}
              {showAlertRedIcon && (
                <AlertRedIcon
                  width={23}
                  height={23}
                  title={'Bei der Übermittlung sind Fehler aufgetreten. Bitte kontrollieren Sie alle Schriftsätze mit dem Status "Zurückgewiesen" oder "Erneut senden (Übertragungsfehler)".'}
                />
              )}
              {!showAlertIcon && (
                <div className="manz-spinner-grow">
                  <div
                    title={item.tooltip}
                    className={`badge badge-pill ${badgeClassName}`}
                  >
                    {`${count === 0 ? '✔' : count}`}
                  </div>
                </div>
              )}
            </div>
          </NavLink>
        </li>
      );
    });

    return (
      <div
        className="navigation-group"
        key="navigation-group-wrapper"
      >
        <div className="navigation-group-header">
          {iconComponent}
          <span>
            {header}
          </span>
        </div>
        <ul className={isBurgerMenu ? 'navigation-group-items-burger-menu' : 'navigation-group-items'}>
          {linkItems}
        </ul>
      </div>
    );
  }
}

NavigationGroupStatusCounter.defaultProps = {
  items: null,
  header: null,
  countAusgang: 0,
  countEingang: 0,
  requestError: null,
  countZurueckgewiesenAusgang: 0,
  countUebertragungFehlerAusgang: 0,
};

NavigationGroupStatusCounter.propTypes = {
  header: PropTypes.string,
  requestError: PropTypes.object,
  countEingang: PropTypes.number,
  countAusgang: PropTypes.number,
  isBurgerMenu: PropTypes.bool.isRequired,
  iconComponent: PropTypes.element.isRequired,
  countZurueckgewiesenAusgang: PropTypes.number,
  countUebertragungFehlerAusgang: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    disabled: PropTypes.bool,
    click: PropTypes.func,
  })),
};

export default withRouter(NavigationGroupStatusCounter);
