export const API_V_0_0_2_EN = `
openapi: 3.0.3
info:
  title: webERV Software API specification
  description: |-
    This is the webERV Software API specification.
    <br/><br/>
    The requests are stateless and follow the request-response pattern. The webERV software (API) is implemented as a REST web service. Attachments are transmitted as body parts of an HTTP multipart request.
    <br/><br/>
    <strong>Manz Hotline</strong>
    <br/>
    Phone: +43 1 531 61 11
    <br/>
    E-Mail: hotline@manz.at
  version: v0.0.2
servers:
  - url: https://qs-weberv.manz.at/__api/
    description: Test System
  - url: https://weberv.manz.at/__api/
    description: Live System
tags:
  - name: 1) Authentication
    description: Authenticates the API user (or the transferring software) to the API service.
      <br/><br/>
      <strong>1.1) Encryption</strong>
      <br/>
      The webERV software APIs are only accessible via a secure connection (TLS). At least TLS version 1.2 is used as the Transport Layer Security (TLS) protocol.
      <br/><br/>
      <strong>1.2) Authentication</strong>
      <br/>
      The authentication of an API user is done through the client certificate transmitted in the HTTP request. The client certificate must first be stored by MANZ in the webERV software system. The same client certificate should be used for the transfer of the contact data and transfer of the "ERV-message", as well as for the document transfer.
      <br/><br/>
      <strong >
      The software manufacturer ID (as an HTTP parameter "X_SW_ID" in the HTTP header) and the SSL certificate (client certificate) must be transmitted in the HTTP request.
      </strong>
      <br/><br/>
      <strong>1.3) Authorization</strong>
      <br/>
      Every authenticated participant who is authorized to use the webERV software APIs.
  - name: 2) Contact
    description: search / create / update / delete contact
  - name: 3) ERV-message
  - name: 4) Participant
  - name: 5) Document
    description: In progress...
paths:
  /external/kontakte:
    get:
      tags:
        - 2) Contact
      summary: Search contacts
      description: Search through the list of contacts created via the endpoint, with the specified attributes "kuerzel", "kontaktType", "vorname", "nachname", "strasse", "plz", "ort" or "geburtsdatum".
      operationId: findKontakte
      parameters:
        - name: kuerzel
          in: query
          description:
            Unique identifier of a contact with a maximum length of 16 characters. This is automatically generated and returned when a contact is created via the api in the webERV Software.
          required: false
          schema:
            type: string
            maximum: 16
          example: MaxMus
        - name: kontaktType
          in: query
          required: false
          schema:
            type: string
            enum:
              - NATUERLICH
              - JURISTISCH
              - UNBEKANNT
          example: NATUERLICH
        - name: vorname
          in: query
          required: false
          description: First name / Vorname (DE) of the natural person or Name2 of the legal person, up to 250 characters long.
          schema:
            type: string
            maximum: 250
          example: Max
        - name: nachname
          in: query
          required: false
          description: Last name / nachname (DE) of the natural person or Name1 of the legal person, up to 250 characters long.
          schema:
            type: string
            maximum: 250
          example: Mustermann
        - name: strasse
          in: query
          required: false
          description: Street of the contact, up to 100 characters long.
          schema:
            type: string
            maximum: 100
          example: Teststrasse
        - name: plz
          in: query
          required: false
          description: Postal code of the contact, up to 10 characters long.
          schema:
            type: string
            maximum: 10
          example: 1010
        - name: ort
          in: query
          required: false
          description: City of the contact, up to 100 characters long.
          schema:
            type: string
            maximum: 100
          example: Wien
        - name: geburtsdatum
          in: query
          required: false
          description: Birth date of the contact. (only nutrual person).
          schema:
            type: date
          example: 1970-01-01
      responses:
        200:
          description: Returns a list of contact overviews
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: "#/components/schemas/QueryKontakteResponse"
        400:
          description: Bad Request or Invalid Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Resource not found.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in the webERV software, in this case please contact the MANZ hotline (+43 1 531 61 11 or hotline@manz.at (Mon - Fri 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    post:
      tags:
        - 2) Contact
      summary: Create a contact
      description: Create a contact
      operationId: createKontakt
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/KontaktDTO"
      responses:
        200:
          description: Result of the contact creation
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/KontaktDTO"
        400:
          description: Bad Request or Invalid Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Resource not found.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in the webERV software, in this case please contact the MANZ hotline (+43 1 531 61 11 or hotline@manz.at (Mon - Fri 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    put:
      tags:
        - 2) Contact
      summary: Update a contact
      description: contact update
      operationId: updateKontakt
      parameters:
        - name: kuerzel
          in: path
          description:
            Unique identifier of a contact. This is automatically generated and returned when a contact is created in the webERV software. After creation, "kuerzel" should not be changed (read-only)!
            <br/>
            <br/>
            When creating a contact, "kuerzel" does not need to be filled in, but when updating or deleting the contact, "kuerzel" must be present and resubmitted!
          required: true
          schema:
            type: string
            maximum: 16
          example: MaxMus
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/KontaktDTO"
      responses:
        200:
          description: Result of the contact update
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/KontaktDTO"
        400:
          description: Bad Request or Invalid Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Resource not found.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in the webERV software, in this case please contact the MANZ hotline (+43 1 531 61 11 or hotline@manz.at (Mon - Fri 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    delete:
      tags:
        - 2) Contact
      summary: Delete a contact
      description: Delete a contact
      operationId: deleteKontakt
      parameters:
        - name: kuerzel
          in: path
          description:
            Unique identifier of a contact. This is automatically generated and returned when a contact is created in the webERV software. After creation, "kuerzel" should not be changed (read-only)!
            <br/>
            <br/>
            When creating a contact, "kuerzel" does not need to be filled in, but when updating or deleting the contact, "kuerzel" must be present and resubmitted!
          required: true
          schema:
            type: string
            maximum: 16
          example: MaxMus
      responses:
        204:
          description: Not Content
        400:
          description: Bad Request or Invalid Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Resource not found.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in the webERV software, in this case please contact the MANZ hotline (+43 1 531 61 11 or hotline@manz.at (Mon - Fri 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
  /external/schriftsaetze:
    post:
      tags:
        - 3) ERV-message
      summary: create a ERV-message (Schriftsatz)
      description: When creating a ERV-message (Schriftsatz), the attributes "applikationsTyp", "advmCode", "aktenzeichen" and "inhaltArt" must be entered in the object "inhalt".
      operationId: createSchriftsatz
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/SchriftsatzDTO"
      responses:
        200:
          description: Result of creating the ERV-message (Schriftsatz)
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/SchriftsatzDTO"
        400:
          description: Bad Request or Invalid Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Resource not found.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in the webERV software, in this case please contact the MANZ hotline (+43 1 531 61 11 or hotline@manz.at (Mon - Fri 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
  /external/schriftsaetze/beteiligte:
    post:
      tags:
        - 4) Participant
      summary: create a participant (Beteiligter)
      description: Creating a participant (Beteiligter) or a party of the ERV-message (Schriftsatz). When creating a participant, the attributes "rolle" and "kontaktKuerzel" must be entered.
      operationId: createBeteiligter
      parameters:
        - name: schriftsatzId
          in: path
          description:
            Unique identifier of a ERV-message (Schriftsatz). This is automatically generated and returned when a ERV-message (Schriftsatz) is created in the webERV software. After creation, "schriftsatzId" should not be changed (read-only)!
          required: true
          schema:
            type: number
          example: 123456
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/BeteiligterDTO"
      responses:
        200:
          description: Result of creating the participant (Beteiligter) of the ERV-message (Schriftsatz)
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/BeteiligterDTO"
        400:
          description: Bad Request or Invalid Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Resource not found.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in the webERV software, in this case please contact the MANZ hotline (+43 1 531 61 11 or hotline@manz.at (Mon - Fri 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
components:
  schemas:
    QueryKontakteResponse:
      type: object
      properties:
        kontaktType:
          type: string
          nullable: false
          enum:
            - NATUERLICH
            - JURISTISCH
            - UNBEKANNT
          example: JURISTISCH
        kuerzel:
          description:
            Unique identifier of a contact. This is automatically generated and returned when a contact is created in the webERV software. After creation, "kuerzel" should not be changed (read-only)!
            <br/>
            <br/>
            When creating a contact, "kuerzel" does not need to be filled in, but when updating or deleting the contact, "kuerzel" must be present and resubmitted!
          type: string
          nullable: false
          maximum: 16
          example: MaxMus
        vorname:
          description: First name of the natural person or Name2 of the legal person.
          type: string
          nullable: true
          maximum: 250
          example: Max
        nachname:
          description: Last name of the natural person or Name1 of the legal person.
          type: string
          nullable: false
          maximum: 250
          example: Mustermann
    ErrorResponse:
      type: object
      properties:
        errorCode:
          type: string
          description: Fehlercode
          nullable: true
        errorDetailCode:
          type: array
          description: Fehlerdetails
          nullable: true
          items:
            $ref: "#/components/schemas/ErrorDetailCode"
        errorCategory:
          type: string
          description: Error category
          nullable: true
          enum:
            - CRASH
            - ERROR
            - WARNING
            - HIN
        timestamp:
          type: integer
          description: Timestamp (UNIX timestamp with milliseconds).
          nullable: true
          format: int64
          example: 2025-01-23T09:52:22.189874Z
        logNr:
          type: UUID
          description: UUID of the log error
          nullable: true
        logMessage:
          type: string
          description: Log of the error message
          nullable: true
        exceptionName:
          type: string
          description: Exception name in the log
          nullable: true
    ErrorDetailCode:
      type: object
      properties:
        id:
          type: string
          description: Fehlerdetail ID
          nullable: true
        params:
          type: object
          additionalProperties:
            type: object
            nullable: true
            properties:
              key:
                type: integer
              value:
                type: string
      description: List of additional error messages.
      nullable: true
    KontaktDTO:
      type: object
      properties:
        kontaktType:
          type: string
          nullable: false
          enum:
            - NATUERLICH
            - JURISTISCH
            - UNBEKANNT
          example: JURISTISCH
        kuerzel:
          description:
            Unique identifier of a contact. This is automatically generated and returned when a contact is created in the webERV software. After creation, "kuerzel" should not be changed (read-only)!
            <br/>
            <br/>
            When creating a contact, "kuerzel" does not need to be filled in, but when updating or deleting the contact, "kuerzel" must be present and resubmitted!
          type: string
          nullable: true
          maximum: 16
          example: MaxMus
        affix:
          description: Affix or title before the contact's name. (only natural person)
          type: string
          nullable: true
          maximum: 37
          example: Dr.
        suffix:
          description: Suffix or title after the contact's name. (only natural person)
          type: string
          nullable: true
          maximum: 37
          example: Bakk.
        beruf:
          description: Profession of the contact. (only legal entity)
          type: string
          nullable: true
          maximum: 37
          example: Rechtsanwalt
        advmCode:
          description: AdvmCode of the contact (e.g. R123456).
          pattern: "([a-zA-Z][0-9]{6})|([a-zA-Z]{2}[0-9]{5})"
          type: string
          nullable: true
          maximum: 7
          example: A123456
        name1:
          description: Last name of the natural person or Name1 of the legal person.
          type: string
          nullable: false
          maximum: 250
          example: Mustermann
        name2:
          description: First name of the natural person or Name2 of the legal person.
          type: string
          nullable: true
          maximum: 250
          example: Max
        staatIsoAlpha3:
          description: ISO Alpha-3 country codes of nationality (only natural person)
          type: string
          nullable: true
          maximum: 3
          example: AUT
        staatStatus:
          description: Nationality status (only natural person)
          nullable: true
          enum:
            - STAATENLOS
            - UNGEKLAERT
            - GEKLAERT
            - UNBEKANNT
          example: GEKLAERT
        herkunftslandIsoAlpha3:
          description: ISO Alpha-3 country codes of the company headquarters (only legal person)
          type: string
          nullable: true
          maximum: 3
          example: AUT
        asylHerkunftsland:
          description: Country of origin of the natural person (only natural person)
          type: string
          nullable: true
          maximum: 37
          example: Deutschland
        geschlecht:
          description: Gender of the natural person (only natural person)
          nullable: true
          enum:
            - MAENNLICH
            - WEIBLICH
            - UNBEKANNT
          default: UNBEKANNT
          example: MAENNLICH
        geburtsdatum:
          description: Date of birth of the contact. (only natural person)
          nullable: true
          type: date
          example: 1970-01-01
        registerType:
          description: Register type of the legal entity (only legal person)
          nullable: true
          enum:
            - FIRMENBUCH
            - VEREINSREGISTER
            - SONSTIGE
            - GEWERBEREGISTER
            - ERGAENZUNGSREGISTER_FUER_SONSTIGE_BETROFFENE
          example: FIRMENBUCH
        registerNummer:
          description: Register number of the legal entity (only legal person)
          type: string
          nullable: true
          maximum: 32
          example: a123456
        notizen:
          description: Notes on the contact
          type: string
          nullable: true
          maximum: 8192
        adresse:
          $ref: "#/components/schemas/AdresseDTO"
        kontaktDetailList:
          type: array
          description: "Contact details such as email, phone, website, etc.
          items:
            $ref: "#/components/schemas/KontaktDetailDTO"
        kontoList:
          type: array
          description: Bank accounts of the contact
          items:
            $ref: "#/components/schemas/KontoDTO"
    AdresseDTO:
      type: object
      properties:
        lkz:
          description: ISO Alpha-3 country codes
          type: string
          nullable: false
          maximum: 3
          example: AUT
        ort:
          description: Ort
          type: string
          nullable: false
          maximum: 100
          example: Wien
        plz:
          description: Postal code (abbr. ZIP)
          type: string
          nullable: false
          maximum: 10
          example: 1010
        strasse:
          description: Street name
          type: string
          nullable: false
          maximum: 100
          example: Musterstrasse
        nr:
          description: Street number
          type: string
          nullable: false
          maximum: 50
          example: 2
    KontaktDetailDTO:
      type: object
      properties:
        kontaktDetailType:
          description: Contact detail type
          nullable: false
          enum:
            - ASYLNAME
            - TELEFON
            - FAX
            - EMAIL
            - WEBSEITE
            - MOBIL
          example: WEBSEITE
        daten:
          description: Detail content
          type: string
          nullable: false
          maximum: 80
          example: https://weberv.manz.at/home
        bemerkung:
          description: Detail description
          type: string
          nullable: true
          maximum: 128
          example: webERV Software
    KontoDTO:
      type: object
      properties:
        kontoType:
          description: Bank account type
          nullable: false
          enum:
            - GEBUEHRENEINZUG
            - ZAHLUNGEN
            - SONSTIGE
          example: GEBUEHRENEINZUG
        bezeichnung:
          description: account designation
          type: string
          nullable: true
          maximum: 32
        iban:
          description: IBAN - International Bank Account Number
          type: string
          nullable: false
          maximum: 34
          example: AT772011100123456789
        swift:
          description: Swift - Bank Identifier Code
          type: string
          nullable: true
          maximum: 11
          example: RZTIAT22263
        iso3Lc:
          description: ISO Alpha-3 country codes
          type: string
          nullable: true
          maximum: 3
          example: AUT
        blz:
          description: BLZ
          type: string
          nullable: true
          maximum: 35
          example: 123456
    SchriftsatzDTO:
      type: object
      properties:
        id:
          type: string
          description:
            <br/>
            The "id" of the ERV-message (Schriftsatz) is automatically generated and returned when a ERV-message (Schriftsatz) is created in the webERV software.
            <br/>
            After creation, the "id" must not be changed (read-only)!
            <br/>
            <br/>
            <strong>
            When creating the parties and documents of a ERV-message (Schriftsatz), the "id" must be present and transmitted again as a unique identifier/identification!
            </strong>
          nullable: false
        applikationsTyp:
          type: string
          nullable: false
          enum:
            - BW
            - TLDZ
            - VJ
            - STA
            - UNKNOWN
          description:
            <br/>
            Application type of a ERV-message (Schriftsatz)
            <br/>
            Description of the application types&#58;
            <table>
            <thead>
            <tr>
            <td>Application type</td>
            <td>Description</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>BW</td>
            <td>Bundesverwaltungsgerichtshof (Federal Administrative Court)</td>
            </tr>
            <tr>
            <td>TLDZ</td>
            <td>Teilnehmerdirektzustellung (direct delivery to participants)</td>
            </tr>
            <tr>
            <td>VJ</td>
            <td>Verfahrensautomation der Justiz (procedural automation of the justice system)</td>
            </tr>
            <tr>
            <td>STA</td>
            <td>Staatsanwaltschaften (public prosecutor's office)</td>
            </tr>
            <tr>
            <td>UNKNOWN</td>
            <td>Undefined application type</td>
            </tr>
            </tbody>
            </table>
          example: BW
        advmCode:
          description: AdvmCode (e.g. R123456) of the consignor(Einbringer)/webERV software profile.
            <br/>
            <strong>
            This AdvmCode must already be present in the webERV software!
            </strong>
          pattern: "([a-zA-Z]|JB)[0-9]*"
          type: string
          nullable: false
          maximum: 7
          example: A123456
        aktenzeichen:
          description: Internal file number/name of the ERV-message (Schriftsatz)
          type: string
          nullable: false
          maximum: 25
          example: ABCnr. XY123456
        inhalt:
          description:
            <strong>
            The type of ERV-message (Schriftsatz) or the content of the ERV-message (Schriftsatz) according to the "inhaltArt".
            </strong>
            <br/>
            <br/>
            The description of the "inhaltArten" can be found in the following table&#58;
            <table>
            <thead>
            <tr>
            <td>inhaltArt</td>
            <td>Description</td>
            <td>Application type</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>VF_ASYLGH</td>
            <td>Bundesverwaltungsgerichtshof (Federal Administrative Court)t</td>
            <td>BW</td>
            </tr>
            <tr>
            <td>TLDZ</td>
            <td>Teilnehmerdirektzustellung (direct delivery to participants)</td>
            <td>TLDZ</td>
            </tr>
            <tr>
            <td>VJ_Serst</td>
            <td>Sonstige Ersteingabe (Other initial message to the court)</td>
            <td>VJ</td>
            </tr>
            <tr>
            <td>VJ_Folge</td>
            <td>Sonstige Folgeeingabe (Other follow-up message to the court)</td>
            <td>VJ</td>
            </tr>
            <tr>
            <td>STA_Eingabe</td>
            <td>Eingabe an die Staatsanwaltschaft (message to the public prosecutor's office)</td>
            <td>STA</td>
            </tr>
            <tr>
            <td>UNKNOWN</td>
            <td>unknown content</td>
            <td>UNKNOWN</td>
            </tr>
            </tbody>
            </table>
          oneOf:
            - $ref: "#/components/schemas/BundesverwaltungsgerichtshofDTO"
            - $ref: "#/components/schemas/DirektzustellungDTO"
            - $ref: "#/components/schemas/SonstigeErsteingabeDTO"
            - $ref: "#/components/schemas/SonstigeFolgeeingabeDTO"
            - $ref: "#/components/schemas/EingabeAnStaatsanwaltschaftenDTO"
    BundesverwaltungsgerichtshofDTO:
      type: object
      description: Bundesverwaltungsgericht
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "VF_ASYLGH" must be entered for "Bundesverwaltungsgericht"!
            </strong>
          nullable: false
          enum:
            - VF_ASYLGH
          example: VF_ASYLGH
        gebuehrenIndikator:
          type: string
          description: fees
          nullable: true
          enum:
            - GESETZLICH_BEFREIT
            - JA
            - NEIN
          example: GEBUEHRENEINZUG
    DirektzustellungDTO:
      type: object
      description: Direktzustellung
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "TLDZ" must be entered for "Direktzustellung"!
            </strong>
          nullable: false
          enum:
            - TLDZ
          example: TLDZ
        mitteilung:
          type: string
          description: notice
          nullable: true
    SonstigeErsteingabeDTO:
      type: object
      description: Sonstige Ersteingabe
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "VJ_Serst" must be entered for "Sonstige Ersteingabe"!
            </strong>
          nullable: false
          enum:
            - VJ_Serst
          example: VJ_Serst
        gericht:
          type: string
          description: Court, e.g. "001" (District Court of Innere Stadt Vienna)
          pattern: "([0-9]{3}|S[0-9][A-Z])"
          nullable: true
          example: 001
        anzahlAusfertigungen:
          type: string
          description: Number of copies, maximum 3 copies
          nullable: true
          minimum: 1
          maximum: 3
        streitwertBeschreibung:
          type: string
          description: Description of the amount in dispute, enter a text in addition to the case code. (150 characters)
          nullable: true
          maximum: 150
        streitwert:
          type: number
          format: double
          description: amount in dispute
          nullable: true
          minimum: 0.00
          maximum: 99999999999.99
        geschaeftszahl:
          $ref: "#/components/schemas/GeschaeftszahlDTO"
    SonstigeFolgeeingabeDTO:
      type: object
      description: Sonstige Folgeeingabe
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "VJ_Folge" must be entered for "Sonstige Folgeeingabe"!
            </strong>
          nullable: false
          enum:
            - VJ_Folge
          example: VJ_Folge
        gericht:
          type: string
          description: Court, e.g. "001" (District Court of Innere Stadt Vienna)
          pattern: "([0-9]{3}|S[0-9][A-Z])"
          nullable: true
          example: 001
        anzahlAusfertigungen:
          type: string
          description: Number of copies, maximum 3 copies
          nullable: true
          minimum: 1
          maximum: 3
        streitwertBeschreibung:
          type: string
          description: Description of the amount in dispute, enter a text in addition to the case code. (150 characters)
          nullable: true
          maximum: 150
        geschaeftszahl:
          $ref: "#/components/schemas/GeschaeftszahlDTO"
    EingabeAnStaatsanwaltschaftenDTO:
      type: object
      description: Eingabe an die Staatsanwaltschaften
      properties:
        inhaltArt:
          type: string
          description:
            <strong>
            inhaltArt "STA_Eingabe" must be entered for "Staatsanwaltschaften"!
            </strong>
          nullable: false
          enum:
            - STA_Eingabe
          example: STA_Eingabe
        gericht:
          type: string
          description: Court, e.g. "001" (District Court of Innere Stadt Vienna)
          pattern: "([0-9]{3}|S[0-9][A-Z])"
          nullable: true
          example: 001
        betreff:
          type: string
          description: Regarding
          nullable: true
          maximum: 100
    GeschaeftszahlDTO:
      type: object
      description: business number
      properties:
        abteilungsNummer:
          type: number
          description: Department number (must be 3 digits; e.g. Dept. "5" you enter "005")
          nullable: false
          maximum: 3
          example: 005
        gattung:
          type: string
          description: Court genres(or Category)
          nullable: false
          example: A
        laufendeNummer:
          type: number
          description: Sequential number (can have 5 characters)
          nullable: false
          minimum: 1
          maximum: 999999
          example: 22222
        jahr:
          type: number
          description: Year (must be 4 digits, e.g. 2021)
          nullable: false
          minimum: 1920
          maximum: 9999
          example: 2001
        pruefKennzeichen:
          type: string
          description: Check code (1 character, case sensitive)
          nullable: false
          minimum: 1
          maximum: 1
          example: m
    BeteiligterDTO:
      type: object
      description: Participat Object
      properties:
        rolle:
          type: string
          description: The allowed "Beteiligtenrollen" of a participant (Beteiligter) involved in a ERV-message (Schriftsatz) are described for the respective "inhaltArten" (Schriftsatzarten) in the following table.
            <br/>
            <br/>
            Description of the participant role&#58;
            <table>
            <thead>
            <tr>
            <td>Participant roles</td>
            <td>Description</td>
            <td>"inhaltArten" (Schriftsatzarten)</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>ANTRAGSTELLER</td>
            <td>Antragsteller (applicant)</td>
            <td>VF_ASYLGH, TLDZ</td>
            </tr>
            <tr>
            <td>ANTRAGSGEGNER</td>
            <td>Antragsgegner (defendant)</td>
            <td>VF_ASYLGH, TLDZ</td>
            </tr>
            <tr>
            <td>MITBETEILIGTE_PARTEI</td>
            <td>Mitbeteiligte Partei (co-involved party)</td>
            <td>VF_ASYLGH, TLDZ</td>
            </tr>
            <tr>
            <td>ERSTE_PARTEI</td>
            <td>Erste Partei (First Party)</td>
            <td>VJ_Serst</td>
            </tr>
            <tr>
            <td>ZWEITE_PARTEI</td>
            <td>Zweite Partei (Second Party)</td>
            <td>VJ_Serst</td>
            </tr>
            <tr>
            <td>SONSTIGER_BETEILIGTER</td>
            <td>Sonstiger Beteiligter (Other party involved)</td>
            <td>VJ_Folge</td>
            </tr>
            </tbody>
            </table>
          nullable: false
          enum:
            - ANTRAGSTELLER
            - ANTRAGSGEGNER
            - MITBETEILIGTE_PARTEI
            - ERSTE_PARTEI
            - ZWEITE_PARTEI
            - SONSTIGER_BETEILIGTER
          example: ANTRAGSTELLER
        rollenbeschreibung:
          type: string
          description: The role description may only be used for the "inhaltArt" "VJ_Folge."
          nullable: true
          example: 1. Kläger
        kontaktKuerzel:
          type: string
          description: Contact abbreviation of the participant/party (Beteiligter) involved; the corresponding contact must already be present in the webERV software.
          nullable: false
          example: MaxMu
        vertreterKontaktKuerzel:
          type: string
          description: Contact abbreviation of a representative (Vertreter - e.g., AdvmCode of the representative; e.g., R123456) of the participant; the corresponding contact must already be present in the webERV software.
          nullable: true
          example: R123456
        vertreterType:
          type: string
          description: the representative type (vertreterType) of a representative of the participant (Beteiligter); when the attribute "vertreterKontaktKuerzel" is filled in, the "vertreterType" must also be filled in.
            <br/>
            <br/>
            Description of representative types&#58;
            <table>
            <thead>
            <tr>
            <td>representative type (vertreterType)</td>
            <td>"inhaltArten" (Schriftsatzarten)</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>EINBRINGER</td>
            <td>VF_ASYLGH, VJ_Serst (only for the participant role "ERSTE_PARTEI" (First Party)), VJ_Folge</td>
            </tr>
            <tr>
            <td>RECHTSANWALT</td>
            <td>VF_ASYLGH</td>
            </tr>
            <tr>
            <td>SONSTIGER</td>
            <td>VF_ASYLGH, TLDZ, VJ_Serst, VJ_Folge</td>
            </tr>
            </tbody>
            </table>
          nullable: true
          enum:
            - EINBRINGER
            - RECHTSANWALT
            - SONSTIGER
          example: SONSTIGER
        vertreterDirektzustellung:
          type: boolean
          description: Representative (Vertreter) - TLDZ according to §112 ZPO, deliverable in ERV; it does not need to be filled in.
            <br/>
            If it is not filled in, it will be automatically set to "true."
          nullable: true
          example: true
          default: true

`;
