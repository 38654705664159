import React from 'react';
import PropTypes from 'prop-types';
import { withConnectedUserData } from '@auth/auth-oidc-integration';
import { withRouter } from 'react-router-dom';
import PATH from '../../constants/path.json';
import Header from './Header';
import Navigation from './Navigation';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import { arrayHasItem } from '../../utils/general.utils';
import BERECHTIGUNGEN from '../../constants/berechtigungen';
import getFormattedMessage from '../../common/FormatMessage/formattedMessage';

/**
 * Layout component
 * @param {Object} props props
 * @constructor
 */
function AppWindowLayout(props) {
  const { appAuthorized, appUserObj, location } = props;

  const isAdmin = (appUserObj && (arrayHasItem(appUserObj.berechtigungen) && appUserObj.berechtigungen.find((v) => v === BERECHTIGUNGEN.ADMIN))) || false;
  const canKontaktImport = (appUserObj && (arrayHasItem(appUserObj.berechtigungen) && appUserObj.berechtigungen.find((v) => v === BERECHTIGUNGEN.KONTAKTIMPORT))) || false;

  const canOpenAPISpezifikation = (appUserObj && (arrayHasItem(appUserObj.berechtigungen) && appUserObj.berechtigungen.find((v) => v === BERECHTIGUNGEN.ERV_API_SPEZIFIKATION))) || false;
  const isHelpSite = (location && location.pathname && location.pathname === PATH.HILFE.value);

  const navigationMenuComponent = appAuthorized && Navigation;
  const navigationMenuParams = {
    isAdmin,
    appAuthorized,
    canKontaktImport,
    canOpenAPISpezifikation,
  };

  let errMsg;
  if (appUserObj) {
    if (appUserObj.errorDetailCode) {
      errMsg = getFormattedMessage(appUserObj.errorDetailCode);
    } else if (appUserObj.errorCode) {
      errMsg = getFormattedMessage(appUserObj.errorCode);
    }
  }

  const appContent = errMsg
    ? (
      <h6 className="text-center margin-top-sx">
        {errMsg}
        <span>
          <a href="mailto:hotline@manz.at" className="full-width manz-link" title="MANZ Hotline" aria-label="MANZ Hotline"> MANZ Hotline</a>
        </span>
        .
      </h6>
    )
    : (
      <>
        <div className="breadcrumbsLayout d-none d-lg-block">
          {!isHelpSite && (
            <Breadcrumbs
              className="breadcrumbs"
            />
          )}
        </div>
        <div className="layout">
          <div className="rolleStyle">
            {!isHelpSite && navigationMenuComponent && (
              <div className="mr-4 d-none d-lg-block">
                { React.createElement(navigationMenuComponent, navigationMenuParams) }
              </div>
            )}
            <div className="content padding-lg">
              {props.children}
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <Header
        navigationMenuComponent={navigationMenuComponent}
        navigationMenuParams={navigationMenuParams}
      />

      {appContent}
    </>
  );
}

AppWindowLayout.defaultProps = {
  children: null,
  appUserObj: null,
  appAuthorized: false,
};

AppWindowLayout.propTypes = {
  children: PropTypes.node,
  appUserObj: PropTypes.object,
  appAuthorized: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

export default withRouter(withConnectedUserData(AppWindowLayout));
