import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PATH from '../constants/path.json';
import { checkOpenInProcessingAPISwitch } from '../constants/js/application.config';

/**
 * component
 */
class APIs extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   */
  constructor(props) {
    super(props);

    this.state = {};

    this.handleOpenAPI = this.handleOpenAPI.bind(this);
  }

  /**
   * Navigates to the API documentation page, passing the specified API version and language as state.
   *
   * @param {string} apiVersion - The API version to be displayed in the documentation.
   * @param {string} language - The language in which the documentation should be displayed.
   * @return {void} This method does not return a value.
   */
  handleOpenAPI(apiVersion, language) {
    const { history } = this.props;
    history.push({
      pathname: `${PATH.API_DOC.value}${apiVersion}${language}`,
    });
  }

  /**
   * Renders the API version information along with optional links for German and English documentation.
   *
   * @param {string} title - The title to display for the API version.
   * @param {string} version - The version of the API to render.
   * @param {boolean} isProcessing - Flag indicating whether the API is currently being processed.
   * @return {JSX.Element} A React JSX element containing the API version title and links to documentation.
   */
  renderAPIVersion(title, version, isProcessing) {
    return (
      <>
        <span className="font-weight-bold font-size-13px">
          {version.label}
        </span>
        <span>
          {title}
        </span>
        <span className="manz-link" onClick={() => this.handleOpenAPI(version.value, PATH.API_DOC_DE.value)}>
          DE
        </span>
        {!isProcessing && (
          <>
            <span> | </span>
            <span className="manz-link" onClick={() => this.handleOpenAPI(version.value, PATH.API_DOC_EN.value)}>
              EN
            </span>
          </>
        )}
        {isProcessing && (
          <span className="font-weight-bold">
            {' (In Arbeit...) '}
          </span>
        )}
      </>
    );
  }

  /**
   * render function
   * @returns {JSX} component
   */
  render() {
    return (
      <div className="padding-content">
        <h2>webERV Software API Spezifikationen</h2>

        <li className="">
          {this.renderAPIVersion(': Authentifizierung und Kontakt ', PATH.API_DOC_V001, false)}
        </li>

        <li>
          {this.renderAPIVersion(': Authentifizierung, Kontakt und Schriftsatz (mit Beteiligter) ', PATH.API_DOC_V002, false)}
        </li>

        <li>
          {this.renderAPIVersion(': Authentifizierung, Kontakt und  Schriftsatz (mit Beteiligter und Dokumente) ', PATH.API_DOC_V100, false)}
        </li>

        {checkOpenInProcessingAPISwitch() && (
          <>
          </>
        )}

      </div>
    );
  }
}

APIs.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(APIs);
