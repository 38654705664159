import SELECT_OPTION from '../gb.begehren.select.option';
import PATH from '../path.json';
import BEGEHREN_ART from '../gb.begehren.art.json';
import { begehrenUrkundenhinterlegungsgesetzArrowDownTooltip } from './tooltip.constaints';
import BETEILIGTE_ROLLE from '../beteiligte.rolle.json';
import {
  GESETZ_10_ABS_5,
  GESETZ_11_ABS_3,
  GESETZ_15_3_AGRVG,
  GESETZ_17_ABS_2,
  GESETZ_19S,
  GESETZ_1Z1_GGG,
  GESETZ_1Z2_GGG,
  GESETZ_25_4_GGG,
  GESETZ_25a_GGG,
  GESETZ_31_ABS_1,
  GESETZ_31A_ABS_9,
  GESETZ_34_BBG,
  GESETZ_41_ABS_4,
  GESETZ_42_WSG,
  GESETZ_53_ABS_1,
  GESETZ_53_GBG,
  GESETZ_53_WFG,
  GESETZ_6_ABS_4,
  GESETZ_NEU_FOEG,
} from './gesetz.constants';

export const SONSTIGE_BEGEHREN = 'Sonstige Begehren';
export const GRUNDSTUECKSVERAENDERUNGEN = 'Grundstücksveränderungen';
export const EIGENTUMSRECHT = 'Eigentumsrecht';
export const PFANDRECHT = 'Pfandrecht';
export const LOESCHUNGEN = 'Löschungen';
export const WOHNUNGSEIGENTUM = 'Wohnungseigentum';
export const RANGORDNUNG = 'Rangordnung';
export const PERSONENAENDERUNG = 'Personenänderung';
export const URKUNDENHINTERLEGUNG_REGISTER = 'Urkundenhinterlegungsregister';

export const GB_VERTRETUNGSVOLLMACHT = [
  {
    value: null,
    label: 'Eingeben oder Auswählen...',
  },
  {
    value: 'Vollmacht gemäß § 30 Abs (2) ZPO und § 77 (1) sowie (2) GBG erteilt',
    label: 'Vollmacht gemäß § 30 Abs (2) ZPO und § 77 (1) sowie (2) GBG erteilt',
  },
  {
    value: 'Vollmacht gem. § 5 (4a) NO und § 77 Abs 1 u. 2 GBG erteilt',
    label: 'Vollmacht gem. § 5 (4a) NO und § 77 Abs 1 u. 2 GBG erteilt',
  },
  {
    value: 'Vollmacht gemäß § 30 (2) ZPO erteilt',
    label: 'Vollmacht gemäß § 30 (2) ZPO erteilt',
  },
  {
    value: 'Vollmacht gemäß § 77 (1) GBG erteilt',
    label: 'Vollmacht gemäß § 77 (1) GBG erteilt',
  },
  {
    value: 'laut Vertrag erteilt',
    label: 'laut Vertrag erteilt',
  },
];

export const EINGABE_GEBUEHR_OPTIONS = [
  {
    value: null,
    label: 'Auswählen...',
  },
  {
    value: 'GEBUEHRENVORSCHREIBUNG',
    label: 'VOR) Gebührenvorschreibung (per Zahlschein)',
  },
  {
    value: 'KEINE_GEBUEHREN',
    label: 'FREI) keine Gebühren/Gebührenbefreiung',
  },
  {
    value: 'GEBUEHRENEINZUG',
    label: 'EINZ) Gebühreneinzug',
  },
];

export const BEGEHREN_TABLE_COLS = [
  {
    dataKey: 'index',
    sortColumn: 'reihung',
    label: 'Nr.',
  },
  {
    dataKey: 'einlagezahl',
    label: SELECT_OPTION.ez,
    sortColumn: 'einlagezahl',
    ignoreSort: true,
  },
  {
    dataKey: 'katastralgemeinde',
    label: 'Katastralgemeinde',
    sortColumn: 'element.katastralgemeinde',
    ignoreSort: true,
  },
  {
    dataKey: 'begehrenArt',
    label: 'Begehren',
    sortColumn: 'begehrenTyp',
  },
  {
    dataKey: 'subArt',
    label: 'Subtyp',
    sortColumn: 'element.inhaltElementTyp',
    ignoreSort: true,
  },
  {
    dataKey: 'aktion',
    label: 'Aktion',
    sortColumn: 'aktion',
    ignoreSort: true,
  },
  {
    dataKey: 'btns',
    ignoreSort: true,
  },
];

export const BEGRUENDUNGSZEILE_COLS = [
  {
    dataKey: 'index',
    label: 'Nr.',
    ignoreSort: true,
  },
  {
    dataKey: 'mindestanteil',
    label: 'Mindestanteil',
  },
  {
    dataKey: 'weImRang',
    label: 'WE im Rang',
  },
  {
    dataKey: 'objectbezeichnung',
    label: 'Objektbezeichnung',
  },
  {
    dataKey: 'verb',
    label: 'VERB',
  },
  {
    dataKey: 'person',
    label: 'Person',
  },
  {
    dataKey: 'ausBLNR',
    label: 'Aus BLNR',
  },
  {
    dataKey: 'er',
    label: 'ER',
  },
  {
    dataKey: 'urkunde',
    label: 'Urkunde',
  },
  {
    dataKey: 'erImRang',
    label: 'ER im Rang',
  },
  {
    dataKey: 'btns',
  },
];

export const EIGENTUMSANTEILSZEILE_COLS = [
  {
    dataKey: 'select',
    label: '',
    ignoreSort: true,
  },
  {
    dataKey: 'index',
    label: 'Nr.',
    ignoreSort: true,
  },
  {
    dataKey: 'anteilsgroesse',
    label: 'Anteilsgröße',
    ignoreSort: true,
  },
  {
    dataKey: 'weVerbindung',
    label: 'Verbindung Wohnungseigentum',
    ignoreSort: true,
  },
  {
    dataKey: 'eintragungFuer',
    label: 'Eintragung für',
    ignoreSort: true,
  },
  {
    dataKey: 'urkunde',
    label: 'Urkunde',
    ignoreSort: true,
  },
  {
    dataKey: 'imRang',
    label: 'Im Rang',
    ignoreSort: true,
  },
  {
    dataKey: 'ausBLNR',
    label: 'Aus BLNR',
    ignoreSort: true,
  },
  {
    dataKey: 'blnrZZ',
    label: 'BLNR ZZ',
    ignoreSort: true,
  },
  {
    dataKey: 'zeilenZZ',
    label: 'Zeilen ZZ',
    ignoreSort: true,
  },
  {
    dataKey: 'btns',
    ignoreSort: true,
  },
];

export const EIGENTUMSANTEILSZEILE_UH_COLS = [
  {
    dataKey: 'select',
    label: '',
    ignoreSort: true,
  },
  {
    dataKey: 'index',
    label: 'Nr.',
    ignoreSort: true,
  },
  {
    dataKey: 'anteilsgroesse',
    label: 'Anteilsgröße',
    ignoreSort: true,
  },
  {
    dataKey: 'weVerbindung',
    label: 'Verbindung Wohnungseigentum',
    ignoreSort: true,
  },
  {
    dataKey: 'eintragungFuer',
    label: 'Eintragung für',
    ignoreSort: true,
  },
  {
    dataKey: 'urkunde',
    label: 'Urkunde',
    ignoreSort: true,
  },
  {
    dataKey: 'imRang',
    label: 'Im Rang',
    ignoreSort: true,
  },
  {
    dataKey: 'ausBLNR',
    label: 'Aus BLNR',
    ignoreSort: true,
  },
  {
    dataKey: 'blnrZZ',
    label: 'BLNR ZZ',
    ignoreSort: true,
  },
  {
    dataKey: 'zeilenZZ',
    label: 'Zeilen ZZ',
    ignoreSort: true,
  },
  {
    dataKey: 'eintragungsart',
    label: 'UH- Eintragungsart',
    ignoreSort: true,
  },
  {
    dataKey: 'btns',
    ignoreSort: true,
  },
];

export const AENDERUNGSZEILE_COLS = [
  {
    dataKey: 'index',
    label: 'Nr.',
    ignoreSort: true,
  },
  {
    dataKey: 'blnr',
    label: 'BLNR',
  },
  {
    dataKey: 'lit',
    label: 'Literal',
  },
  {
    dataKey: 'objectbezeichnung',
    label: 'Objektbzeichnung',
  },
  {
    dataKey: 'anteilsgroesse',
    label: 'Anteilsgröße',
  },
  {
    dataKey: 'btns',
  },
];

export const GRUNDBUCH_AUSZUG_BETEILIGTER_COLS = [
  {
    dataKey: 'reihung',
    label: 'Nr.',
    ignoreSort: true,
  },
  {
    dataKey: 'beschreibung',
    label: 'Person',
    ignoreSort: true,
  },
  {
    label: 'Rolle',
    dataKey: 'actions',
  },
];

export const GB_AUSZUG_BEGEHREN_B_LNRS_LITERAL_COLS = [
  {
    dataKey: 'select',
    label: '',
    ignoreSort: true,
  },
  {
    dataKey: 'lnr',
    label: 'B-LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'anteil',
    label: 'Anteil',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'eigentuemer',
    label: 'Eigentümer / Eintragungstext',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_C_LNRS_LITERAL_COLS = [
  {
    dataKey: 'select',
    label: '',
    ignoreSort: true,
  },
  {
    dataKey: 'lnr',
    label: 'B-LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'anteil',
    label: 'Anteil',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'eigentuemer',
    label: 'Eigentümer / Eintragungstext',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_ABC_LNRS_LITERAL_WITHOUT_SELECT_COLS = [
  {
    dataKey: 'lnr',
    label: 'LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'anteil',
    label: 'Anteil',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'eigentuemer',
    label: 'Eigentümer / Eintragungstext',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_B_LNRS_LITERAL_WITHOUT_SELECT_COLS = [
  {
    dataKey: 'lnr',
    label: 'B-LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'anteil',
    label: 'Anteil',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'eigentuemer',
    label: 'Eigentümer / Eintragungstext',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_TAGBUCHZAHL_WITHOUT_SELECT_COLS = [
  {
    dataKey: 'lnr',
    label: 'B-LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'anteil',
    label: 'Anteil',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'eigentuemer',
    label: 'Eigentümer / Eintragungstext',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_C_LNRS_EZ_KG_COLS = [
  {
    dataKey: 'select',
    label: '',
    ignoreSort: true,
  },
  {
    dataKey: 'lnr',
    label: 'C-LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'last',
    label: 'Eintragungstext',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_GRUNDSTUECK_COLS = [
  {
    dataKey: 'select',
    label: '',
    ignoreSort: true,
  },
  {
    dataKey: 'gstNr',
    label: 'GST-Nr.',
    ignoreSort: true,
  },
  {
    dataKey: 'bezeichnung',
    label: 'Bezeichnung',
    ignoreSort: true,
  },
  {
    dataKey: 'flaeche',
    label: 'Fläche',
    ignoreSort: true,
  },
  {
    dataKey: 'adresse',
    label: 'Adresse',
    ignoreSort: true,
  },
];

export const GB_AUSZUG_BEGEHREN_C_LNRS_LITERAL_COLS_WITHOUT_SELECT_COLS = [
  {
    dataKey: 'lnr',
    label: 'C-LNR',
    ignoreSort: true,
  },
  {
    dataKey: 'tagebuchzahl',
    label: 'Tagebuchzahl',
    ignoreSort: true,
  },
  {
    dataKey: 'last',
    label: 'Eintragungstext',
    ignoreSort: true,
  },
];

export const BEGEHREN_SUPER_ART = [
  {
    disabled: false,
    value: SONSTIGE_BEGEHREN,
    label: SONSTIGE_BEGEHREN,
  },
  {
    disabled: false,
    value: GRUNDSTUECKSVERAENDERUNGEN,
    label: GRUNDSTUECKSVERAENDERUNGEN,
  },
  {
    disabled: false,
    value: EIGENTUMSRECHT,
    label: EIGENTUMSRECHT,
  },
  {
    disabled: false,
    value: PFANDRECHT,
    label: PFANDRECHT,
  },
  {
    disabled: false,
    value: LOESCHUNGEN,
    label: LOESCHUNGEN,
  },
  {
    disabled: false,
    value: WOHNUNGSEIGENTUM,
    label: WOHNUNGSEIGENTUM,
  },
  {
    disabled: false,
    value: RANGORDNUNG,
    label: RANGORDNUNG,
  },
  {
    disabled: false,
    value: PERSONENAENDERUNG,
    label: PERSONENAENDERUNG,
  },
  {
    disabled: false,
    value: URKUNDENHINTERLEGUNG_REGISTER,
    label: begehrenUrkundenhinterlegungsgesetzArrowDownTooltip,
    className: 'begehren-new-uh',
  },
];

export const EINTRAGUNGSPFAD_ART_OPTIONS = [
  {
    value: SELECT_OPTION.blatt,
    label: 'Eintragungskategorie (Blatt)',
  },
  {
    value: SELECT_OPTION.ez,
    label: 'eine bestehende Einlage (EZ)',
  },
  {
    value: SELECT_OPTION.new_ez,
    label: 'Neueintragung (neue EZ)',
  },
];

export const BEGEHREN_BEZUG_AUF_OPTIONS = [
  {
    value: SELECT_OPTION.ez,
    label: 'eine bestehende Einlage (EZ)',
  },
  {
    value: SELECT_OPTION.new_ez,
    label: 'Neueintragung (neue EZ)',
  },
];

export const BEGEHREN_NEUEINTRAGUNG_BEZUG_AUF_OPTIONS = [
  {
    value: SELECT_OPTION.ez,
    label: 'eine bestehende Eintragung (B-LNR)',
  },
  {
    value: SELECT_OPTION.new_ez,
    label: 'Neueintragung (anderes Begehren)',
  },
];


export const EIGENTUMSRECHT_BAURECHT_ART_OPTIONS = [
  {
    value: SELECT_OPTION.eigentumsrecht,
    label: 'Eigentumsrecht',
  },
  {
    value: SELECT_OPTION.baurecht,
    label: 'Baurecht',
  },
];

export const EINGTRAGUNG_KATEGORIE_OPTIONS = [
  {
    value: SELECT_OPTION.gutsbestand,
    label: 'Gutsbestand (Eintragung im A2-Blatt der Einlage)',
  },
  {
    value: SELECT_OPTION.eigentum,
    label: 'Eigentum (Eintragung im B-Blatt der Einlage)',
  },
  {
    value: SELECT_OPTION.lasten,
    label: 'Lasten (Eintragung im C-Blatt der Einlage)',
  },
  {
    value: SELECT_OPTION.aufschrift,
    label: 'Aufschrift (Eintragung in der Aufschrift der Einlage)',
  },
  {
    value: SELECT_OPTION.unbekannt,
    label: 'Unbekannt (Es ist nicht klar bzw. unbekannt, in welchen Einlagen-Abschnitt, die begehrte Eintragung zu tätigen ist.)',
  },
];

export const BESTEHENDE_EINTRAGUNG_OPTIONS = [
  {
    value: SELECT_OPTION.ablatt,
    label: 'A-Blatt',
  },
  {
    value: SELECT_OPTION.bblatt,
    label: 'B-Blatt',
  },
  {
    value: SELECT_OPTION.cblatt,
    label: 'C-Blatt',
  },
];

export const DEFAULT_EINTRAGUNGSGEBUEHR_OPTIONS = [
  {
    value: SELECT_OPTION.gebuehrenvorschreibung,
    label: 'VOR) Gebührenvorschreibung (per Zahlschein)',
  },
  {
    value: SELECT_OPTION.gebuehreneinzug,
    label: 'EINZ) Gebühreneinzug',
  },
  {
    value: SELECT_OPTION.selbstberechnung,
    label: 'SBE) Selbstberechnung',
  },
];

export const DEFAULT_GEBUEHREN_BEFREIUNGS_ID_TYP_OPTIONS = [
  {
    value: null,
    label: 'Auswählen...',
    link: null,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par25aggg,
    label: '§ 25a GGG',
    link: GESETZ_25a_GGG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par25abs4ggg,
    label: '§ 25 Abs 4 GGG',
    link: GESETZ_25_4_GGG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par26abs1z1ggg,
    label: '§ 26a Abs 1 Z1 GGG',
    link: GESETZ_1Z1_GGG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par26abs1z2ggg,
    label: '§ 26a Abs 1 Z2 GGG',
    link: GESETZ_1Z2_GGG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par53gbg,
    label: '§ 53 GBG',
    link: GESETZ_53_GBG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par53wfg,
    label: '§ 53 WFG',
    link: GESETZ_53_WFG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par42wsg,
    label: '§ 42 WSG',
    link: GESETZ_42_WSG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_par15abs3agrvg,
    label: '§ 15 Abs 3 AGRVG',
    link: GESETZ_15_3_AGRVG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_art34bbg,
    label: 'Art 34 BBG',
    link: GESETZ_34_BBG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_neufoeg,
    label: 'NeuFöG',
    link: GESETZ_NEU_FOEG,
  },
  {
    value: SELECT_OPTION.gebuehren_befreiungs_id_typ_sonstige,
    label: 'Sonstige',
    link: null,
  },
];

export const EINTRAGUNGSGEBUEHR_WITHOUT_SBE_OPTIONS = [
  {
    value: SELECT_OPTION.gebuehrenvorschreibung,
    label: 'VOR) Gebührenvorschreibung (per Zahlschein)',
  },
  {
    value: SELECT_OPTION.gebuehreneinzug,
    label: 'EINZ) Gebühreneinzug',
  },
];

export const EINTRAGUNGSGEBUEHR_WITHOUT_FREI_OPTIONS = [
  {
    value: SELECT_OPTION.gebuehrenvorschreibung,
    label: 'VOR) Gebührenvorschreibung (per Zahlschein)',
  },
  {
    value: SELECT_OPTION.gebuehreneinzug,
    label: 'EINZ) Gebühreneinzug',
  },
];

export const WAEHRUNG_OPTIONS = [
  {
    value: null,
    label: 'Auswählen...',
  },
  {
    value: 'EUR',
    label: 'EUR) Euro',
  },
  {
    value: 'DKK',
    label: 'DKK) Dänische Kronen',
  },
  {
    value: 'GBP',
    label: 'GBP) Britische Pfund',
  },
  {
    value: 'SEK',
    label: 'SEK) Schwedische Kronen',
  },
  {
    value: 'ISK',
    label: 'ISK) Isländische Kronen',
  },
  {
    value: 'CHF',
    label: 'CHF) Schweizer Franken',
  },
  {
    value: 'NOK',
    label: 'NOK) Norwegische Kronen',
  },
];

export const WAEHRUNG_OPTIONS_WITHOUT_NULL = [
  {
    value: 'EUR',
    label: 'EUR) Euro',
  },
  {
    value: 'DKK',
    label: 'DKK) Dänische Kronen',
  },
  {
    value: 'GBP',
    label: 'GBP) Britische Pfund',
  },
  {
    value: 'SEK',
    label: 'SEK) Schwedische Kronen',
  },
  {
    value: 'ISK',
    label: 'ISK) Isländische Kronen',
  },
  {
    value: 'CHF',
    label: 'CHF) Schweizer Franken',
  },
  {
    value: 'NOK',
    label: 'NOK) Norwegische Kronen',
  },
];

export const SONSTIGE_AENDERUNG_BEZUG_AUF = [
  {
    value: SELECT_OPTION.aufschrift,
    label: 'Aufschrift',
  },
  {
    value: SELECT_OPTION.gutsbestand,
    label: 'Gutbestand',
  },
  {
    value: SELECT_OPTION.eine_bestehende_einlage,
    label: 'eine bestehende Einlage (EZ)',
  },
];

export const TRENNSTUECK_TABELLE_BEZUG_ANTEILE_OPTIONS = [
  {
    value: SELECT_OPTION.neu,
    label: 'Neu',
  },
  {
    value: SELECT_OPTION.aenderung,
    label: 'Änderung',
  },
  {
    value: SELECT_OPTION.loeschen,
    label: 'Löschen',
  },
  {
    value: SELECT_OPTION.umnummerierung,
    label: 'Umnummerierung',
  },
  {
    value: SELECT_OPTION.kg_grenzaenderung,
    label: 'KG-Grenzänderung',
  },
];

export const TRENNSTUECK_TABELLE_ZIELEINLAGE_OPTIONS = [
  {
    value: SELECT_OPTION.ez,
    label: 'eine bestehende Einlage (EZ)',
  },
  {
    value: SELECT_OPTION.new_ez,
    label: 'Neueintragung (neue EZ)',
  },
  {
    value: SELECT_OPTION.ein_bestehendes_grundstueck,
    label: 'ein bestehendes Grundstück',
  },
];

export const TRENNSTUECK_TABELLE_BEZUG_ANTEILE_GESCHAEFTSFALL_OPTIONS = [
  {
    value: SELECT_OPTION.planbasiert,
    label: 'P - Planbescheinigung',
  },
  {
    value: SELECT_OPTION.sonderverfahren_agrarverfahren,
    label: 'S - Sonderverfahren/Agrarverfahren',
  },
  {
    value: SELECT_OPTION.kg_grenzaenderung,
    label: 'G - KG-Grenzänderungen',
  },
  {
    value: SELECT_OPTION.umnummerierung,
    label: 'U - Gst-Umnummerierungen',
  },
];

export const ER_GEGENSATNDSART_OPTIONS = [
  {
    value: SELECT_OPTION.beschraenkung_nachfolgerecht,
    label: 'Beschränkung: Nachfolgerecht',
  },
  {
    value: SELECT_OPTION.beschraenkung_aufloesende_bedingung,
    label: 'Beschränkung: Auflösende Bedingung',
  },
  {
    value: SELECT_OPTION.beschraenkung_immobilien_investmentfondsgesetz,
    label: 'Beschränkung: § 4 Abs. 4 Immobilien-Investmentfondgesetz',
  },
  {
    value: SELECT_OPTION.beschraenkung_guetergemeinschaft,
    label: 'Beschränkung: Gütergemeinschaft',
  },
  {
    value: SELECT_OPTION.beschraenkung_fideikommissarische_substitution,
    label: 'Beschränkung: Nacherbschaft (Fideikommissarische Substitution)',
    tooltip: 'Eine Nacherbschaft darf sich nur auf das Vermögen beziehen, das von dem Verstorbenen stammt. \n'
      + 'Der Erbe, der das Vermögen zuerst bekommt, darf das Vermögen nutzen, aber nicht verbrauchen \n'
      + '(zB. von einem Sparbuch lediglich die Zinsen beheben).',
  },
  {
    value: SELECT_OPTION.rechtfertigung,
    label: 'Rechtfertigung',
  },
  {
    value: SELECT_OPTION.widmung_deckungsstock,
    label: 'Widmung Deckungsstock',
  },
  {
    value: SELECT_OPTION.widmung_kaution,
    label: 'Widmung Kaution',
  },
];

export const EIGENTUMSRECHT_AENDERUNGSART_OPTIONS = [
  {
    value: SELECT_OPTION.anteilszusammenziehung,
    label: 'Anteilszusammenziehung',
  },
  {
    value: SELECT_OPTION.anteilsabstrennung,
    label: 'Anteilsabtrennung',
  },
];

export const EIGENTUMSRECHT_VOM_ANTEIL_OPTIONS = [
  {
    value: SELECT_OPTION.bestehender_anteil,
    label: 'Bestehender Anteil',
  },
  {
    value: SELECT_OPTION.neuer_anteil,
    label: 'Neuer Anteil',
  },
];

export const EIGENTUMSRECHT_AENDERUNG_BEZUG_AUF = [
  {
    value: SELECT_OPTION.blnr,
    label: 'BLNR',
  },
  {
    value: SELECT_OPTION.begehrensreferenz,
    label: 'Begehrensreferenz',
  },
];

export const PFANDRECHT_ART_OPTIONS = [
  {
    value: true,
    label: 'Festbetrag',
  },
  {
    value: false,
    label: 'Höchstbetrag',
  },
];

export const PFANDRECHT_GEGENSTAND_DER_ANMERKUNG_OPTIONS = [
  {
    value: SELECT_OPTION.widmung_deckungsstock,
    label: 'Widmung für Deckungsstock',
  },
  {
    value: SELECT_OPTION.widmung_kaution,
    label: 'Widmung für Kaution',
  },
  {
    value: SELECT_OPTION.vollstreckbarkeit,
    label: 'Vollstreckbarkeit',
  },
];

export const PFANDRECHT_GEGENSTAND_DER_ANMERKUNG_OPTIONS_DEPRECATED = [
  {
    value: SELECT_OPTION.widmung_deckungsstock,
    label: 'Widmung für Deckungsstock',
  },
  {
    value: SELECT_OPTION.widmung_kaution,
    label: 'Widmung für Kaution',
  },
  {
    value: SELECT_OPTION.kautionsband,
    label: 'Kautionsband (Veraltet)',
  },
  {
    value: SELECT_OPTION.vollstreckbarkeit,
    label: 'Vollstreckbarkeit',
  },
];

export const PFANDRECHT_GEGENSTAND_OPTIONS = [
  {
    value: SELECT_OPTION.rechtfertigung,
    label: 'Rechtfertigung',
  },
  {
    value: SELECT_OPTION.widmung_deckungsstock,
    label: 'Widmung für Deckungsstock',
  },
  {
    value: SELECT_OPTION.widmung_kaution,
    label: 'Widmung für Kaution',
  },
  {
    value: SELECT_OPTION.vollstreckbarkeit,
    label: 'Vollstreckbarkeit',
  },
];

export const PFANDRECHT_GEGENSTAND_OPTIONS_DEPRECATED = [
  {
    value: SELECT_OPTION.rechtfertigung,
    label: 'Rechtfertigung',
  },
  {
    value: SELECT_OPTION.widmung_deckungsstock,
    label: 'Widmung für Deckungsstock',
  },
  {
    value: SELECT_OPTION.widmung_kaution,
    label: 'Widmung für Kaution',
  },
  {
    value: SELECT_OPTION.kautionsband,
    label: 'Kautionsband (Veraltet)',
  },
  {
    value: SELECT_OPTION.vollstreckbarkeit,
    label: 'Vollstreckbarkeit',
  },
];

export const LOESCHUNGEN_GESETZESGRUNDLAGE_OPTIONS = [
  {
    value: '§ 51 GBG',
    label: '§ 51 GBG',
  },
  {
    value: '§ 57 GBG',
    label: '§ 57 GBG',
  },
  {
    value: '§ 40 (1) WEG',
    label: '§ 40 (1) WEG',
  },
  {
    value: '§ 40 (2) WEG',
    label: '§ 40 (2) WEG',
  },
];

export const TEIL_LOESCHUNG_OPTIOSN = [
  {
    value: SELECT_OPTION.grundstueck,
    label: 'Grundstück (Grundstücksnummern, die zu löschen sind)',
  },
  {
    value: SELECT_OPTION.personen,
    label: 'Personen (Personen mittels Referenz oder Namen die zu löschen sind)',
  },
  {
    value: SELECT_OPTION.teilbetrag,
    label: 'Teilbetrag (Angabe des Betrages, um den sich ein aktuell in der Eintragung aufscheinender Betrag auf den anzugebenden, nach der Teillöschung aufscheinen sollenden Betrag reduziert.)',
  },
  {
    value: SELECT_OPTION.eintragungen,
    label: 'Eintragungen (A-, B-, C-LNR die zu löschen sind)',
  },
  {
    value: SELECT_OPTION.sonstige,
    label: 'Sonstige (Freitext, für die bisher nicht strukturiert erfassten Teillöschungsfälle)',
  },
];

export const EINVERLEIBUNG_OR_EINFACHE_OR_CBLATT_OPTIONS = [
  {
    value: SELECT_OPTION.einverleibung_der_loeschung,
    label: 'Einverleibung der Löschung',
  },
  {
    value: SELECT_OPTION.einfache_loeschung,
    label: 'Einfache Löschung',
  },
  {
    value: SELECT_OPTION.loeschung_c_blatt,
    label: 'Löschung C-Blatt',
  },
];

export const AUFSCHRIFT_OR_GUTSBESTAND_OR_EZ_OPTIONS = [
  {
    value: SELECT_OPTION.aufschrift,
    label: 'Aufschrift',
  },
  {
    value: SELECT_OPTION.gutsbestand,
    label: 'Gutsbestand',
  },
  {
    value: SELECT_OPTION.bestehende_einlage,
    label: 'eine bestehende Einlage (EZ)',
  },
];

export const KEINEAUSWAHL_OR_TEILLOESCHUNG_OR_UNTERAUSNUTZUNG_OPTIONS = [
  {
    value: SELECT_OPTION.keine_auswahl,
    label: 'Keine Auswahl',
  },
  {
    value: SELECT_OPTION.teil_loeschung,
    label: 'Teil-Löschung',
  },
  {
    value: SELECT_OPTION.rang_vorbehalt,
    label: 'unter Ausnutzung des Rangvorbehalts',
  },
];

export const ORANGORDNUNG_GEGENSTAND_OPTIONS = [
  {
    value: SELECT_OPTION.liegenschaft,
    label: 'Liegenschaft',
  },
  {
    value: SELECT_OPTION.grundstueck,
    label: 'Grundstück',
  },
];

export const WE_ANDERKUNG_DER_OPTIONS = [
  {
    value: SELECT_OPTION.vorbehalten_verpfaendung,
    label: 'vorbehaltenen Verpfändung',
  },
  {
    value: SELECT_OPTION.zusage_der_einraeumung,
    label: 'Zusage der Einräumung',
  },
  {
    value: SELECT_OPTION.uebertraung_der_zusage_der_einraeumung,
    label: 'Übertragung der Zusage der Einräumung',
  },
];

export const ERSICHTLICHMACHUNG_OPTIONS = [
  {
    value: SELECT_OPTION.abweichenden_abrechnungseinheit_gemaess_32_weg_2002,
    label: 'abweichenden Abrechnungseinheit gemäß § 32 WEG 2002',
  },
  {
    value: SELECT_OPTION.abweichenden_abstimmungseinheit_gemaess_32_weg_2002,
    label: 'abweichenden Abstimmungseinheit gemäß § 32 WEG 2002',
  },
  {
    value: SELECT_OPTION.abweichenden_aufteilung_der_aufwendungen_gemaess_32_weg_2002,
    label: 'abweichenden Aufteilung der Aufwendungen gemäß § 32 WEG 2002',
  },
  {
    value: SELECT_OPTION.benuetzungsregelung_gemaess_17_weg_2002,
    label: 'Benützungsregelung gemäß § 17 WEG 2002',
  },
  {
    value: SELECT_OPTION.gemeinschaftsordnung_gemaess_26_weg_2002,
    label: 'Gemeinschaftsordnung gemäß § 26 WEG 2002',
  },
  {
    value: SELECT_OPTION.verwalter_gemaess_19_weg_2002,
    label: 'Verwalter gemäß § 19 WEG 2002 (wird bei „Eintragung für“ angegeben)',
  },
  {
    value: SELECT_OPTION.eigentuemervertreter_gemasess_22_weg_2002,
    label: 'Eigentümervertreter gemäß § 22 WEG 2002 (wird bei „Eintragung für“ angegeben)',
  },
];

export const AUSFERTIGSWUNSCH_OPTIONS = [
  {
    value: false,
    label: 'Papier',
  },
  {
    value: true,
    label: 'Elektronisch',
  },
];

export const BUNDESLAND_OPTIONS = [
  {
    value: 'BURGENLAND_1',
    label: 'Burgenland (1): § 10 Abs. 5',
    hinweis: {
      href: GESETZ_10_ABS_5,
      linkText: '§ 16 Bgld WFG 2018',
      suffixText: null,
      prefixText: 'Hinweis: gemäß § 10 Abs. 5 der Richtlinie 2018/2019 zur Förderung des Ankaufs von Eigenheimen, Wohnungen und Reihenhäusern iVm ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'BURGENLAND_2',
    label: 'Burgenland (2): § 13 Abs. 4',
    hinweis: {
      href: GESETZ_10_ABS_5,
      linkText: '§ 16 Bgld WFG 2018',
      suffixText: null,
      prefixText: 'Hinweis: gemäß § 13 Abs. 4 der Richtlinie 2018/2019 zur Förderung der Errichtung von Eigenheimen für den privaten Wohnbau iVm ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'BURGENLAND_3',
    label: 'Burgenland (3): § 19 Abs. 3',
    hinweis: {
      href: GESETZ_10_ABS_5,
      linkText: '§ 16 Bgld WFG 2018',
      suffixText: null,
      prefixText: 'Hinweis: gemäß § 19 Abs. 3 der Richtlinie 2018/2019 zur Förderung der Sanierung von Eigenheimen für den privaten Wohnbau iVm ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'BURGENLAND_4',
    label: 'Burgenland (4): § 35 Abs. 5',
    hinweis: {
      href: GESETZ_10_ABS_5,
      linkText: '§ 16 Bgld WFG 2018',
      suffixText: null,
      prefixText: 'Hinweis: gemäß § 35 Abs. 5 der Richtlinie 2018/2019 zur Förderung der Errichtung von Gruppenwohnbauten, Reihenhäusern, Wohnungen und Wohnheimen iVm ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'BURGENLAND_5',
    label: 'Burgenland (5): § 42 Abs. 2',
    hinweis: {
      href: GESETZ_10_ABS_5,
      linkText: '§ 16 Bgld WFG 2018',
      suffixText: null,
      prefixText: 'Hinweis: gemäß § 42 Abs. 2 der Richtlinie 2018/2019 zur Förderung der Sanierung von Gruppenwohnbauten, Reihenhäusern, Wohnungen und Wohnheimen iVm ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'BURGENLAND_6',
    label: 'Burgenland (6): § 17 Abs. 2',
    hinweis: {
      href: GESETZ_17_ABS_2,
      linkText: 'Bgld. WFG 2018',
      suffixText: null,
      prefixText: 'Hinweis: gemäß § 17 Abs. 2 der Richtlinie idgF zur Förderung der Sanierung von Eigenheimen, Reihenhäusern und Wohnungen im privaten Wohnbau für den Ausstieg aus fossilen Energieträgern gemäß dem Bgld. Wohnbauförderungsgesetz 2018 – ',
      mitteText: ' idgF und den Sonderförderrichtlinien 2023.',
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'KAERNTEN',
    label: 'Kärnten: gemäß § 31 Abs. 1 K-WBFG 2017 idgF',
    hinweis: {
      href: GESETZ_31_ABS_1,
      linkText: '§ 31 Abs. 1 K-WBFG 2017 idgF',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'NIEDEROESTERREICH',
    label: 'Niederösterreich (1): § 11 Abs. 3 NÖ WFG 2005',
    hinweis: {
      href: GESETZ_11_ABS_3,
      linkText: '§ 11 Abs. 3 NÖ WFG 2005',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'NIEDEROESTERREICH_2',
    label: 'Niederösterreich (2): § 31a Abs. 9 NÖ WFR 2019',
    hinweis: {
      href: null,
      linkText: null,
      suffixText: null,
      prefixText: 'Hinweis: gem. § 31a Abs. 9 NÖ Wohnungsförderungsrichtlinie 2019',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'OBEROESTERREICH',
    label: 'Oberösterreich: § 28 Abs. 2 OÖ WFG 1993',
    hinweis: {
      href: GESETZ_31A_ABS_9,
      linkText: '§ 28 Abs. 2 OÖ WFG 1993',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'SALZBURG',
    label: 'Salzburg: § 19 S.WFG 2015',
    hinweis: {
      href: GESETZ_19S,
      linkText: '§ 19 S.WFG 2015',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'STEIERMARK',
    label: 'Steiermark: § 53 Abs. 1 Stmk WFG 1993',
    hinweis: {
      href: GESETZ_53_ABS_1,
      linkText: '§ 53 Abs. 1 Stmk WFG 1993',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'TIROL',
    label: 'Tirol',
  },
  {
    value: 'VORARLBERG',
    label: 'Vorarlberg',
  },
  {
    value: 'WIEN_1',
    label: 'Wien (1): § 41 Abs. 4 WWFSG 1989',
    hinweis: {
      href: GESETZ_41_ABS_4,
      linkText: '§ 41 Abs. 4 WWFSG 1989',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
  {
    value: 'WIEN_2',
    label: 'Wien (2): § 6 Abs. 4 WWFSG 1989',
    hinweis: {
      href: GESETZ_6_ABS_4,
      linkText: '§ 6 Abs. 4 WWFSG 1989',
      suffixText: null,
      prefixText: 'Hinweis: gemäß ',
      mitteText: null,
      secondHref: null,
      secondLinkText: null,
    },
  },
];

export const PERSONAENDERUNG_OPTIONS = [
  {
    value: 'ADRESSE',
    label: 'Adresse',
  },
  {
    value: 'PERSON',
    label: 'Person',
  },
];
export const SONSTIGE_BEGEHREN_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_NE.label,
    description: 'Dient dazu, das Neuanlegen einer Einlage anfordern zu können (Hilfsbegehren). Dies ist notwendig, um sich in einem anderen Begehren desselben Antrages auf die neu angelegte Einlage beziehen zu können - da deren EZ zum Zeitpunkt der Antragstellung noch nicht bekannt ist.',
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_NET.label,
    description: 'Neueintragung mit Bezug auf eine Eintragungskategorie, eine bestehende Einlage (EZ) oder ein anderes Begehren.',
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_EINTRAGUNGSPFAD.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_AE.label,
    description: 'Änderung von Einträgen mit Bezug auf Aufschrift, Gutsbestand oder Eintragung.',
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_VOR.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_VOR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_MUE.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_MUE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_AV.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AV.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const GRUNDSUECKSVERAENDERUNGEN_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_ABZU_GST.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_GV_TSTTAB.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_GV_TSTTAB.value}${PATH.BEGEHREN_TRENNSTUECKTABELLE.value}`,
  },
];
export const EIGENTUMSRECHT_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_ER_ANM.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_ANM.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_ER_AEN.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_AEN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_ER_BER.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_BER.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_ER_EINTR.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_ER_VOR.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_VOR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const PFANDRECHT_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_ANM_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PR_SIM_ANM_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIM_ANM_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_VERAE_EIN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const LOESCHUNGEN_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_LO_LOE.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const LOESCHUNGEN_DURCHFUEHRUNG_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_LO_LOE_V2.aktion,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const URKUNDENHINTERLEGUNG_REGISTER_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_NE_UH.labelUH,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NE_UH.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
    isUrkundenhinterlegungsregister: true,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_NET.labelUH,
    description: 'Neueintragung mit Bezug auf eine Eintragungskategorie, eine bestehende Einlage (EZ) oder ein anderes Begehren.',
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_NET.value}${PATH.BEGEHREN_EINTRAGUNGSPFAD.value}`,
    isUrkundenhinterlegungsregister: true,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_AE.labelUH,
    description: 'Änderung von Einträgen mit Bezug auf Aufschrift, Gutsbestand oder Eintragung.',
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_AE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
    isUrkundenhinterlegungsregister: true,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_ER_EINTR.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_ER_EINTR.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
    isUrkundenhinterlegungsregister: true,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PR_SIN_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
    isUrkundenhinterlegungsregister: true,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_SO_EM_UH.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_SO_EM_UH.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
    isUrkundenhinterlegungsregister: true,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_LO_LOE_V2.aktion,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_LO_LOE_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
    isUrkundenhinterlegungsregister: true,
  },
];
export const WOHNUNGSEIGENTUM_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_BEG_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_AEN_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_WE_UMW_EIN_V2.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_UMW_EIN_V2.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_WE_E_ANM.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ANM.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_WE_E_ERS.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_WE_E_ERS.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const RANGORDNUNG_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_RO_VERAE.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERAE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_RO_VERPF.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_VERPF.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_RO_EIN.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_RO_EIN.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const PERSONENAENDERUNG_DETAIL = [
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PA_AAE.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_AAE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PA_NAE.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_NAE.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
  {
    disabled: false,
    value: BEGEHREN_ART.BEGEHREN_PA_TTL.label,
    description: null,
    url: `${PATH.SCHRIFTSATZ.value}${PATH.GB_AN.value}${PATH.BEGEHREN.value}${BEGEHREN_ART.BEGEHREN_PA_TTL.value}${PATH.BEGEHREN_BEZUG_ANTEILE.value}`,
  },
];
export const BEGEHREN_ART_DETAIL_MAP = {
  [PFANDRECHT]: PFANDRECHT_DETAIL,
  [RANGORDNUNG]: RANGORDNUNG_DETAIL,
  [EIGENTUMSRECHT]: EIGENTUMSRECHT_DETAIL,
  [SONSTIGE_BEGEHREN]: SONSTIGE_BEGEHREN_DETAIL,
  [WOHNUNGSEIGENTUM]: WOHNUNGSEIGENTUM_DETAIL,
  [PERSONENAENDERUNG]: PERSONENAENDERUNG_DETAIL,
  [LOESCHUNGEN]: LOESCHUNGEN_DURCHFUEHRUNG_DETAIL,
  [GRUNDSTUECKSVERAENDERUNGEN]: GRUNDSUECKSVERAENDERUNGEN_DETAIL,
  [URKUNDENHINTERLEGUNG_REGISTER]: URKUNDENHINTERLEGUNG_REGISTER_DETAIL,
};

export const GB_DOKUMENT_FB_AUSGANG_OPTIONS = [
  {
    label: 'AKT) Aktueller (zum heutigen Tag) Firmenbuchauszug',
    value: 'AKT',
  },
  {
    label: 'HIS) Historischer Auszug, der Stichtag wird im Feld \'Stichtag\' angegeben',
    value: 'HIS',
  },
  {
    label: 'TA) Teilauszug (Kurzinformation)',
    value: 'TA',
  },
];

export const GB_BEGEHREN_UH_EINTRAGUNGSART = [
  {
    value: null,
    label: 'Auswählen...',
  },
  {
    value: 'HINTERLEGUNG',
    label: 'Hinterlegung',
  },
  {
    value: 'EINREIHUNG',
    label: 'Einreihung',
  },
];

export const GB_BEGEHREN_UH_PREFIX_KURZ = 'UH-Register: ';
export const GB_BEGEHREN_UH_PREFIX_LANG = 'Urkundenhinterlegungsregister: ';

export const GB_AUSZUG_BETEILIGTER_ROLLE = {
  BETEILIGTER: BETEILIGTE_ROLLE.BETEILIGTER.value,
  ANTRAGSTELLER: BETEILIGTE_ROLLE.ANTRAGSTELLER.value,
};

export const GB_AUSZUG_BETEILIGTER_ROLLE_OPTIONS = [
  {
    value: GB_AUSZUG_BETEILIGTER_ROLLE.BETEILIGTER,
    label: 'Beteiligter',
  },
  {
    value: GB_AUSZUG_BETEILIGTER_ROLLE.ANTRAGSTELLER,
    label: 'Antragsteller',
  },
];

export const GB_AUSZUG_BETEILIGTER_TYP = {
  PERSON: 'Person',
  VERTRETER: 'Vertreter',
};

export const GB_EINTRAGUNGSART = [
  {
    value: 'HINSICHTLICH',
    label: 'Hinsichtlich',
  },
  {
    value: 'MIT_AUSNAHME_VON',
    label: 'mit Ausnahme von',
  },
];

export const GB_RECHT_TYP = [
  {
    value: 'ER',
    label: 'Eigentumsrecht',
  },
  {
    value: 'BR',
    label: 'Baurecht',
  },
];
