export const API_V_0_0_1_DE = `
openapi: 3.0.3
info:
  title: webERV Software API Spezifikation
  description: |-
    Dies ist die webERV Software API Spezifikation.
    <br/><br/>
    Die Aufrufe sind zustandslos und entsprechen dem Request-Response-Pattern. Die webERV Software (API) ist als REST-Webservice implementiert. Attachments werden als Bodyparts eines HTTP-Multipart-Requests übermittelt.
    <br/><br/>
    <strong>Manz Hotline</strong>
    <br/>
    Tele: +43 1 531 61 11
    <br/>
    E-Mail: hotline@manz.at
  version: v0.0.1
servers:
  - url: https://qs-weberv.manz.at/__api/
    description: Test System
  - url: https://weberv.manz.at/__api/
    description: Live System
tags:
  - name: 1) Authentifizierung
    description: Authentifiziert den API-Benutzer (bzw. die übergebende Software) gegenüber dem API - Service.
      <br/><br/>
      <strong>1.1) Verschlüsselung</strong>
      <br/>
      Die webERV Software-Schnittstellen sind ausschließlich über eine sichere Verbindung (TLS) erreichbar. Als Transport Layer Security (TLS) Protokoll wird mindestens TLS-Version 1.2 verwendet.
      <br/><br/>
      <strong>1.2) Authentifizierung</strong>
      <br/>
      Die Authentifizierung eines API-Benutzers erfolgt durch das im HTTP-Request übermittelte Client-Zertifikat. Das Client-Zertifikat muss im ersten Schritt von MANZ im System der webERV Software hinterlegt werden. Für die Übergabe der Kontakt- und Schriftsatzdaten, sowie den Dokumententransfer soll dasselbe Client-Zertifikat verwendet werden.
      <br/><br/>
      <strong >
      Im HTTP-Request müssen die Software-Hersteller-ID (als ein HTTP-Parameter "X_SW_ID" im HTTP-Header) und das SSL-Zertifikat (Client-Zertifikat) übermittelt werden.
      </strong>
      <br/><br/>
      <strong>1.3) Autorisierung</strong>
      <br/>
      Jeder authentifizierte Teilnehmer, der für die Nutzung der webERV Software-Schnittstellen autorisiert ist.
  - name: 2) Kontakt
    description: Kontakt suchen / erstellen / aktualisieren / löschen
  - name: 3) Schriftsatz
    description: In Arbeit...
  - name: 4) Dokument
    description: In Arbeit...
paths:
  /external/kontakte:
    get:
      tags:
        - 2) Kontakt
      summary: Kontakte suchen
      description: Nach den Attributen "kuerzel", "kontaktType", "vorname", "nachname", "strasse", "plz", "ort" oder "geburtsdatum"  wird die Liste der Kontakte, die vom Endpoint erstellt wurden, durchsucht.
      operationId: findKontakte
      parameters:
        - name: kuerzel
          in: query
          description:
            Eindeutige Kennung eines Kontaktes mit einer maximalen Länge von 16 Zeichen. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert.
          required: false
          schema:
            type: string
            maximum: 16
          example: MaxMus
        - name: kontaktType
          in: query
          required: false
          schema:
            type: string
            enum:
              - NATUERLICH
              - JURISTISCH
              - UNBEKANNT
          example: NATUERLICH
        - name: vorname
          in: query
          required: false
          description: Vorname der natürlichen Person oder Name2 der juritischen Person, maximal 250 Zeichen lang.
          schema:
            type: string
            maximum: 250
          example: Max
        - name: nachname
          in: query
          required: false
          description: Nachname der natürlichen Person oder Name1 der juritischen Person, maximal 250 Zeichen lang.
          schema:
            type: string
            maximum: 250
          example: Mustermann
        - name: strasse
          in: query
          required: false
          description: Strasse des Kontaktes, maximal 100 Zeichen lang.
          schema:
            type: string
            maximum: 100
          example: Teststrasse
        - name: plz
          in: query
          required: false
          description: PLZ des Kontaktes, maximal 10 Zeichen lang.
          schema:
            type: string
            maximum: 10
          example: 1010
        - name: ort
          in: query
          required: false
          description: Ort des Kontaktes, maximal 100 Zeichen lang.
          schema:
            type: string
            maximum: 100
          example: Wien
        - name: geburtsdatum
          in: query
          required: false
          description: Geburtsdatum des Kontaktes. (nur bei natürliche Personen)
          schema:
            type: date
          example: 1970-01-01
      responses:
        200:
          description: Liefert eine Liste mit Kontakt-Übersichten
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: "#/components/schemas/QueryKontakteResponse"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    post:
      tags:
        - 2) Kontakt
      summary: Erstellen eines Kontaktes
      description: Erstellung eines Kontaktes
      operationId: createKontakt
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/KontaktDTO"
      responses:
        200:
          description: Ergebnis der Erstellung des Kontaktes
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/KontaktDTO"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    put:
      tags:
        - 2) Kontakt
      summary: Aktualisierung eines Kontaktes
      description: Aktualisierung eines Kontaktes
      operationId: updateKontakt
      parameters:
        - name: kuerzel
          in: path
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          required: true
          schema:
            type: string
            maximum: 16
          example: MaxMus
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/KontaktDTO"
      responses:
        200:
          description: Ergebnis der Aktualisierung des Kontaktes
          content:
            application/json:
              schema:
                $ref: "#/components/schemas/KontaktDTO"
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
    delete:
      tags:
        - 2) Kontakt
      summary: Löschung eines Kontaktes
      description: Löschung eines Kontaktes
      operationId: deleteKontakt
      parameters:
        - name: kuerzel
          in: path
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          required: true
          schema:
            type: string
            maximum: 16
          example: MaxMus
      responses:
        204:
          description: Not Content
        400:
          description: Bad Request bzw. Ungültiger Request.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        404:
          description: Ressource nicht gefunden.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        412:
          description: Precondition Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        417:
          description: Expectation Failed.
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        422:
          description: Validation exception
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
        500:
          description: Internal Server Error in der webERV Software, in diesem Fall bitte die MANZ-Hotline kontaktieren (+43 1 531 61 11 oder hotline@manz.at (Mo - Fr. 8:00 - 18:00)).
          content:
            application/json:
              schema:
                "$ref": "#/components/schemas/ErrorResponse"
components:
  schemas:
    QueryKontakteResponse:
      type: object
      properties:
        kontaktType:
          type: string
          nullable: false
          enum:
            - NATUERLICH
            - JURISTISCH
            - UNBEKANNT
          example: JURISTISCH
        kuerzel:
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          type: string
          nullable: false
          maximum: 16
          example: MaxMus
        vorname:
          description: Vorname der natürlichen Person bzw. Name2 der juristischen Personen.
          type: string
          nullable: true
          maximum: 250
          example: Max
        nachname:
          description: Nachname der natürlichen Person bzw. Name1 der juristischen Personen.
          type: string
          nullable: false
          maximum: 250
          example: Mustermann
    ErrorResponse:
      type: object
      properties:
        errorCode:
          type: string
          description: Fehlercode
          nullable: true
        errorDetailCode:
          type: array
          description: Fehlerdetails
          nullable: true
          items:
            $ref: "#/components/schemas/ErrorDetailCode"
        errorCategory:
          type: string
          description: Fehler Kategorie
          nullable: true
          enum:
            - CRASH
            - ERROR
            - WARNING
            - HIN
        timestamp:
          type: integer
          description: Zeitpunkt (UNIX Timestamp mit Millisekunden).
          nullable: true
          format: int64
          example: 2025-01-23T09:52:22.189874Z
        logNr:
          type: UUID
          description: UUID des Logs Fehlers
          nullable: true
        logMessage:
          type: string
          description: Logs der Fehlermeldung
          nullable: true
        exceptionName:
          type: string
          description: Exception Name im Log
          nullable: true
    ErrorDetailCode:
      type: object
      properties:
        id:
          type: string
          description: Fehlerdetail ID
          nullable: true
        params:
          type: object
          additionalProperties:
            type: object
            nullable: true
            properties:
              key:
                type: integer
              value:
                type: string
      description: Liste an weiteren Fehlermeldungen.
      nullable: true
    KontaktDTO:
      type: object
      properties:
        kontaktType:
          type: string
          nullable: false
          enum:
            - NATUERLICH
            - JURISTISCH
            - UNBEKANNT
          example: JURISTISCH
        kuerzel:
          description:
            Eindeutige Kennung eines Kontaktes. Dies wird bei der Kontakt-Erstellung eines Kontaktes in der webERV Software automatisch generiert und zurück geliefert. Nach der Erstellung sollte "kuerzel" nicht geändert (read-only) werden!
            <br/>
            <br/>
            Bei der Erstellung eines Kontaktes muss "kuerzel" nicht befüllt werden, bei der Aktualisierung, oder Löschung des Kontaktes muss "kuerzel" vorhanden sein und erneut übermittelt werden!
          type: string
          nullable: true
          maximum: 16
          example: MaxMus
        affix:
          description: Affix bzw. Titel vorgestellt des Kontaktes. (nur natürliche Person)
          type: string
          nullable: true
          maximum: 37
          example: Dr.
        suffix:
          description: Suffix bzw. Titel nachgestellt des Kontaktes. (nur natürliche Person)
          type: string
          nullable: true
          maximum: 37
          example: Bakk.
        beruf:
          description: Beruf des Kontaktes. (nur juristische Person)
          type: string
          nullable: true
          maximum: 37
          example: Rechtsanwalt
        advmCode:
          description: AdvmCode des Kontaktes (z.B. R123456).
          pattern: "([a-zA-Z][0-9]{6})|([a-zA-Z]{2}[0-9]{5})"
          type: string
          nullable: true
          maximum: 7
          example: A123456
        name1:
          description: Nachname der natürlichen Person oder Name1 der juristischen Personen.
          type: string
          nullable: false
          maximum: 250
          example: Mustermann
        name2:
          description: Vorname der natürlichen Person oder Name2 der juristischen Personen.
          type: string
          nullable: true
          maximum: 250
          example: Max
        staatIsoAlpha3:
          description: ISO Alpha-3-Ländercodes der Nationalität (nur natürliche Person)
          type: string
          nullable: true
          maximum: 3
          example: AUT
        staatStatus:
          description: Nationalität Status (nur natürliche Person)
          nullable: true
          enum:
            - STAATENLOS
            - UNGEKLAERT
            - GEKLAERT
            - UNBEKANNT
          example: GEKLAERT
        herkunftslandIsoAlpha3:
          description: ISO Alpha-3-Ländercodes des Firmensitz (nur juristische Person)
          type: string
          nullable: true
          maximum: 3
          example: AUT
        asylHerkunftsland:
          description: Herkunftsland der natürlichen Person (nur natürliche Person)
          type: string
          nullable: true
          maximum: 37
          example: Deutschland
        geschlecht:
          description: Geschlecht der natürlichen Person (nur natürliche Person)
          nullable: true
          enum:
            - MAENNLICH
            - WEIBLICH
            - UNBEKANNT
          default: UNBEKANNT
          example: MAENNLICH
        geburtsdatum:
          description: Geburtsdatum des Kontaktes. (nur natürliche Person)
          nullable: true
          type: date
          example: 1970-01-01
        registerType:
          description: Registertype der juristischen Person (nur juristische Person)
          nullable: true
          enum:
            - FIRMENBUCH
            - VEREINSREGISTER
            - SONSTIGE
            - GEWERBEREGISTER
            - ERGAENZUNGSREGISTER_FUER_SONSTIGE_BETROFFENE
          example: FIRMENBUCH
        registerNummer:
          description: Registernummer der juristischen Person (nur juristische Person)
          type: string
          nullable: true
          maximum: 32
          example: a123456
        notizen:
          description: Notizen zum Kontakt
          type: string
          nullable: true
          maximum: 8192
        adresse:
          $ref: "#/components/schemas/AdresseDTO"
        kontaktDetailList:
          type: array
          description: Kontaktdaten z.B. E-Mail, Telefon, Webseite usw.
          items:
            $ref: "#/components/schemas/KontaktDetailDTO"
        kontoList:
          type: array
          description: Bankkonten des Kontaktes
          items:
            $ref: "#/components/schemas/KontoDTO"
    AdresseDTO:
      type: object
      properties:
        lkz:
          description: ISO Alpha-3-Ländercodes der Land
          type: string
          nullable: false
          maximum: 3
          example: AUT
        ort:
          description: Ort
          type: string
          nullable: false
          maximum: 100
          example: Wien
        plz:
          description: Postleitzahl (Abk. PLZ)
          type: string
          nullable: false
          maximum: 10
          example: 1010
        strasse:
          description: Straßennamen
          type: string
          nullable: false
          maximum: 100
          example: Musterstrasse
        nr:
          description: Straßennummer
          type: string
          nullable: false
          maximum: 50
          example: 2
    KontaktDetailDTO:
      type: object
      properties:
        kontaktDetailType:
          description: Kontaktdetailstype
          nullable: false
          enum:
            - ASYLNAME
            - TELEFON
            - FAX
            - EMAIL
            - WEBSEITE
            - MOBIL
          example: WEBSEITE
        daten:
          description: Inhalt des Details
          type: string
          nullable: false
          maximum: 80
          example: https://weberv.manz.at/home
        bemerkung:
          description: Inhalt des Details
          type: string
          nullable: true
          maximum: 128
          example: webERV Software
    KontoDTO:
      type: object
      properties:
        kontoType:
          description: Bankkontotype
          nullable: false
          enum:
            - GEBUEHRENEINZUG
            - ZAHLUNGEN
            - SONSTIGE
          example: GEBUEHRENEINZUG
        bezeichnung:
          description: Kontobezeichnung
          type: string
          nullable: true
          maximum: 32
        iban:
          description: IBAN - International Bank Account Number
          type: string
          nullable: false
          maximum: 34
          example: AT772011100123456789
        swift:
          description: Swift - Bank Identifier Code
          type: string
          nullable: true
          maximum: 11
          example: RZTIAT22263
        iso3Lc:
          description: ISO Alpha-3-Ländercodes der Land
          type: string
          nullable: true
          maximum: 3
          example: AUT
        blz:
          description: BLZ
          type: string
          nullable: true
          maximum: 35
          example: 123456

`;
